export const loginMessages = {
  successAdmin: "Login successful admin",
  successUser: "Login successful user",
  noUser: "No such user",
  wrongPassword: "Wrong password",
  emailNotVerified: "Email not verified",
};

export const signUpMessages = {
  successSignUp: "Success",
  userExists: "User exists",
};

export const newsletterMessages = {
  success: "Success",
  alreadyRegistered: "Already registered",
};
