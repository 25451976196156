import React, { useEffect, useState } from "react";
import { serverUrl } from "../../consts/serverUrl";
import { Grid } from "@mui/material";
import useStyles from "./styles";
import { OneProductEdit } from "../OneProductEdit/OneProductEdit";
import { ArrowBack } from "@mui/icons-material";

export const ViewProducts = (props) => {
  const { setPage, setSelectedProduct, navbarHeight, setPreviousPage } = props;

  const [localProducts, setLocalProducts] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState(false);
  const classes = useStyles();

  const handleBackToManage = () => {
    setPage("ManageProducts");
  };

  const renderProductList = () => {
    if (localProducts && localProducts.length > 0) {
      const sortedProductList = localProducts.slice().sort((a, b) => {
        // Check if a.order and b.order are present, and fallback to 0 if they are not present
        const orderA = a.order ?? 0;
        const orderB = b.order ?? 0;

        return orderA - orderB; // Will work fine as both are strings now
      });

      return sortedProductList.map((product) => (
        <Grid item key={product.id}>
          <OneProductEdit
            product={product}
            setSelectedProduct={setSelectedProduct}
            setPage={setPage}
          ></OneProductEdit>
        </Grid>
      ));
    }
  };

  const fetchProducts = async () => {
    const data = await fetch(`${serverUrl}/products`).then((res) => res.json());
    setLocalProducts(data);
    setLoadedProducts(true);
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [setLocalProducts]);

  return (
    <main>
      <div className={classes.cartBackground}>
        <div style={{ height: navbarHeight }}></div>
        <button onClick={handleBackToManage}>
          <ArrowBack></ArrowBack>
          <br></br>Inapoi la Product Manager Page
        </button>
        <div className={classes.centeredContent}>
          Click pe produs pentru a edita!
        </div>
        {!loadedProducts && (
          <div className={classes.loadingHolder}>Produsele se incarca...</div>
        )}
        <Grid container className={classes.productsGridContainer}>
          {renderProductList()}
        </Grid>
      </div>
    </main>
  );
};
