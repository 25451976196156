import React, { useEffect, useState } from "react";
import { serverUrl } from "../../consts/serverUrl";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";

export const ViewOrders = (props) => {
  const { setPage, navbarHeight, setPreviousPage } = props;
  const [localOrders, setLocalOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const handleBackToAdminPage = () => {
    setPage("AdminPage");
  };

  const completeOrder = async (orderId) => {
    const adminJwt = localStorage.getItem("proseccoshop_admin_token") || "";
    if (!adminJwt) {
      alert("Token de admin inexistent!");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminJwt}`,
      },
    };
    const shipOrderResponse = await fetch(
      `${serverUrl}/ship-order/${orderId}`,
      requestOptions
    );
    if (shipOrderResponse.status === 200) {
      alert("Felicitari! Comanda finalizata cu succes!");
      navigate(0);
    } else if (shipOrderResponse.status === 500)
      alert("Eroare la finalizarea comenzii");
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  const renderProductList = () => {
    if (localOrders && localOrders.length > 0) {
      const sortedProductList = localOrders.slice().sort((a, b) => {
        // Check if a.order and b.order are present, and fallback to 0 if they are not present
        const orderA = a.order ?? 0;
        const orderB = b.order ?? 0;

        return orderA - orderB; // Will work fine as both are strings now
      });

      return sortedProductList.map((order) => (
        <Grid item xs={12} sm={6} md={6} lg={6} key={order.orderId}>
          <div>
            <Card
              style={{
                textAlign: "center",
                backgroundImage: "linear-gradient(#fad0c4, #ff9a9e)",
              }}
            >
              <CardContent>
                <Typography variant="h4">{`ID comandă: ${order.orderId}`}</Typography>
                <br />
                <Typography variant="h5">{`Preț: ${order.price} RON`}</Typography>
                <br />
                <Typography variant="h5">{`Data crearii: ${order.dateCreated}`}</Typography>
                <br />
                <Typography variant="h5">{`Data confirmarii: ${
                  order.dateConfirmed ? order.dateConfirmed : "Plata Ramburs!"
                }`}</Typography>
                <br />
                <Typography variant="h5">{`Transport gratuit: ${
                  order.shipingIncluded ? "Da" : "Nu"
                }`}</Typography>
                <br />
                <Typography>{`Tip cumpărător: ${
                  order.customerType === "company"
                    ? "Persoană juridică"
                    : "Persoană fizică"
                }`}</Typography>
                <br />
                <Card style={{ border: "1px solid black" }}>
                  <Typography variant="h5">Rezumat comandă:</Typography>
                  {Object.keys(order).indexOf("order") > -1 &&
                    Object.keys(order.order).map(
                      (orderProductId) =>
                        orderProductId != "total_qty" && (
                          <Card style={{ border: "1px solid black" }}>
                            <div>
                              <br />
                              <Typography>{`Produs: ${order.order[orderProductId].name} (cod ${orderProductId})`}</Typography>
                              <Typography>{`Cantitate: ${order.order[orderProductId].qty}`}</Typography>
                              <Typography>{`Pret unitar: ${order.order[orderProductId].unit_price} RON`}</Typography>
                              <Typography>{`Subtotal: ${
                                order.order[orderProductId].unit_price *
                                order.order[orderProductId].qty
                              } RON`}</Typography>
                              <br />
                            </div>
                          </Card>
                        )
                    )}
                </Card>
                <br />
                <Card style={{ border: "1px solid black" }}>
                  <Typography variant="h5">Facturare:</Typography>
                  <br />
                  <Typography>{`Nume: ${order.billing.billingFirstName}`}</Typography>
                  <br />
                  <Typography>{`Prenume: ${order.billing.billingLastName}`}</Typography>
                  <br />
                  <Typography>{`CNP: ${order.billing.billingCNP}`}</Typography>
                  <br />
                  <Typography>{`Adresă:`}</Typography>
                  <br />
                  <Typography>{`${order.billing.billingAddress}`}</Typography>
                  <br />
                  <Typography>{`Email: ${order.billing.billingEmail}`}</Typography>
                  <br />
                  <Typography>{`Telefon: ${order.billing.billingMobilePhone}`}</Typography>
                  <br />
                  <Typography>{`Nume companie: ${
                    order.billing.billingCompanyName
                      ? order.billing.billingCompanyName
                      : "Nu este cazul"
                  }`}</Typography>
                  <br />
                  <Typography>{`CUI: ${
                    order.billing.billingCIF
                      ? order.billing.billingCIF
                      : "Nu este cazul"
                  }`}</Typography>
                  <br />
                </Card>
                <br />
                <Card style={{ border: "1px solid black" }}>
                  <Typography variant="h5">Livrare:</Typography>
                  <br />
                  <br />
                  <Typography>{`Nume: ${order.shipping.shippingFirstName}`}</Typography>
                  <br />
                  <Typography>{`Prenume: ${order.shipping.shippingLastName}`}</Typography>
                  <br />
                  <Typography>{`CNP: ${order.shipping.shippingCNP}`}</Typography>
                  <br />
                  <Typography>{`Adresă:`}</Typography>
                  <br />
                  <Typography>{`${order.shipping.shippingAddress}`}</Typography>
                  <br />
                  <Typography>{`Email: ${order.shipping.shippingEmail}`}</Typography>
                  <br />
                  <Typography>{`Telefon: ${order.shipping.shippingMobilePhone}`}</Typography>
                  <br />
                  <Typography>{`Nume companie: ${
                    order.shipping.shippingCompanyName
                      ? order.shipping.shippingCompanyName
                      : "Nu este cazul"
                  }`}</Typography>
                  <br />
                  <Typography>{`CUI: ${
                    order.shipping.shippingCIF
                      ? order.shipping.shippingCIF
                      : "Nu este cazul"
                  }`}</Typography>
                  <br />
                </Card>
              </CardContent>
              <Button
                color="success"
                variant="contained"
                onClick={() => completeOrder(order.orderId)}
              >
                Am finalizat comanda
              </Button>
              <br />
              <br />
            </Card>
          </div>
        </Grid>
      ));
    }
  };

  const fetchOrders = async () => {
    const adminJwt = localStorage.getItem("proseccoshop_admin_token") || "";
    if (!adminJwt) {
      alert("Token de admin inexistent!");
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminJwt}`,
      },
    };
    const data = await fetch(
      `${serverUrl}/confirmed-orders`,
      requestOptions
    ).then((res) => res.json());
    setLocalOrders(data);
    setLoadedOrders(true);
  };

  useEffect(() => {
    fetchOrders();
  }, [setLocalOrders]);

  return (
    <main>
      <div className={classes.cartBackground}>
        <div style={{ height: navbarHeight }}></div>
        <button onClick={handleBackToAdminPage}>
          <ArrowBack></ArrowBack>
          <br></br>Inapoi la Admin Page
        </button>
        <br />
        <br />
        {!loadedOrders && (
          <div className={classes.loadingHolder}>Comenzile se incarca...</div>
        )}
        {loadedOrders && localOrders.length === 0 && (
          <div className={classes.loadingHolder}>
            Nu exista comenzi de afisat
          </div>
        )}
        <Grid container className={classes.productsGridContainer}>
          {renderProductList()}
        </Grid>
        <br />
      </div>
    </main>
  );
};
