import React, { createContext, useContext, useEffect, useState } from "react";
import { serverUrl } from "../consts/serverUrl";

const AdminAuthContext = createContext({
  admin: null,
  adminToken: null,
  adminLogin: async (email, password, rememberMe) => {},
  tokenAdminLogin: async (storedToken) => {},
  processLoginAdmin: (userData, userToken, rememberMe) => {},
  logout: () => {},
  isAuthenticatedAdmin: () => false,
  getAdminId: () => "string",
});

export const AdminAuthContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);
  const [adminToken, setAdminToken] = useState(null);
  const [authDone, setAuthDone] = useState(false);
  /**
   * Get the user ID: email if authenticated, "guest" otherwise
   **/
  const getAdminId = () => {
    // If the authentication is done:
    // - If the user is not null and the user has an email, return the user's email
    // - If the user is null or the user does not have an email, return "guest"
    // If the authentication is not done, return "waiting"
    if (!authDone) return "waiting";

    // @ts-ignore
    return admin && admin.email ? admin.email : null;
  };

  const isAuthenticatedAdmin = () => {
    // @ts-ignore
    return admin !== null && adminToken !== null && admin.email !== null;
  };

  // Try to authenticate user with stored token on first render
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("proseccoshop_admin") || "{}");
    const token = localStorage.getItem("proseccoshop_admin_token");
    // Option 1: If user has rememberMe true
    // Option 2: If user does not have rememberMe, but the session is still valid
    if (
      user.email &&
      token &&
      (user.rememberMe || Date.now() < user.sessionExpiration)
    ) {
      tokenAdminLogin(token);
    } else {
      setAuthDone(true);
    }
  }, []);

  const adminLogin = async (email, password, rememberMe) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password }),
    };

    const response = await fetch(
      `${serverUrl}/admin-login`,
      requestOptions
    ).then((res) => res.json());

    const responseMessage = response.message;

    if (responseMessage === "Login successful admin") {
      processLoginAdmin(response.userInfo, response.adminJwtToken, true);
    }
  };

  const processLoginAdmin = (userInfo, authToken, rememberMe) => {
    // Construct the user object to store in local storage
    // Include the session expiration time (24 hours from now)
    // Include the rememberMe flag
    const localStorageUserInfo = {
      sessionExpiration: Date.now() + 1000 * 60 * 60 * 24,
      rememberMe: rememberMe,
      ...userInfo,
    };

    setAdmin(localStorageUserInfo);
    setAdminToken(authToken);

    // Store the user object and token in local storage
    localStorage.setItem(
      "proseccoshop_admin",
      JSON.stringify(localStorageUserInfo)
    );
    // Store the token in local storage
    localStorage.setItem("proseccoshop_admin_token", authToken);

    setAuthDone(true);
  };

  const logout = () => {
    setAdmin(null);
    setAdminToken(null);
    localStorage.removeItem("proseccoshop_admin");
    localStorage.removeItem("proseccoshop_admin_token");
  };

  /**
   * Authenticate admin with stored admin token
   * @param {*} storedToken
   */
  // @ts-ignore
  const tokenAdminLogin = async (storedToken) => {
    try {
      const authResponse = await fetch(`${serverUrl}/admin-login-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      }).then((res) => res.json());

      if (authResponse.message === "Login successful admin") {
        setAdmin(authResponse.userInfo);
        setAdminToken(storedToken);

        localStorage.setItem(
          "proseccoshop_admin",
          JSON.stringify({
            sessionExpiration: Date.now() + 1000 * 60 * 60 * 24,
            ...authResponse.userInfo,
          })
        );
      } else {
        logout();
      }
    } catch (error) {
      console.error("Error during token authentication:", error);
      logout();
    } finally {
      setAuthDone(true);
    }
  };

  return (
    <AdminAuthContext.Provider
      value={{
        admin,
        adminToken,
        adminLogin,
        tokenAdminLogin,
        processLoginAdmin,
        logout,
        // @ts-ignore
        isAuthenticatedAdmin,
        getAdminId,
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};
