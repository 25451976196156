import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { serverUrl } from "../../consts/serverUrl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ArrowBack } from "@mui/icons-material";
import { uploadUrl } from "../../consts/uploadsUrl";

export const EditProduct = (props) => {
  const { setPage, selectedProduct, navbarHeight, setPreviousPage } = props;
  const classes = useStyles();
  const [newImages, setNewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(selectedProduct.images);
  const [removedImages, setRemovedImages] = useState([]);
  const [images, setImages] = useState(selectedProduct.images);

  const [categories, setCategories] = useState({});
  const [fetchedCategories, setFetchedCategories] = useState(false);

  const fetchCategories = async () => {
    let localCategories = {};
    try {
      localCategories = await fetch(`${serverUrl}/categories`).then((res) =>
        res.json()
      );
      if (localCategories.length > 0) setCategories(localCategories);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleBackToView = () => {
    setPage("ViewProducts");
  };

  const handleImageReorder = (result) => {
    if (!result.destination) return;
    const items = Array.from(newImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setNewImages(items);
  };

  const handleRemoveExistingImage = (index) => {
    const updatedImages = [...selectedImages];
    setRemovedImages([...removedImages, updatedImages[index]]);
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleRemoveNewImage = (index) => {
    const updatedImages = [...newImages];
    updatedImages.splice(index - selectedImages.length, 1);
    setNewImages(updatedImages);
  };

  const handleAddMorePhotos = (event) => {
    const files = Array.from(event.target.files);
    setNewImages((prevImages) => [...prevImages, ...files]);
  };

  const handleAddProduct = async () => {
    const adminJwt = localStorage.getItem("proseccoshop_admin_token") || "";
    if (!adminJwt) {
      alert("Token de admin inexistent!");
      return;
    }

    const name = document.getElementById("productName").value;
    const price = parseFloat(
      document.getElementById("productPrice").value.replace(",", "")
    );
    const discount = document.getElementById("discount").value;
    const sku = document.getElementById("productSKU").value;
    const volume = document.getElementById("volume").value;
    const alcohol = document.getElementById("alcohol").value;
    const country = document.getElementById("country").value;
    const category = document.getElementById("category").value;
    const temperature = document.getElementById("temperature").value;
    const associations = document.getElementById("associations").value;
    const description = document.getElementById("productDescription").value;
    const order = document.getElementById("order").value;
    const preffered = document.getElementById("preffered").checked.toString();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("discount", discount);
    formData.append("sku", sku);
    formData.append("volume", volume);
    formData.append("alcohol", alcohol);
    formData.append("country", country);
    formData.append("category", category);
    formData.append("temperature", temperature);
    formData.append("associations", associations);
    formData.append("preffered", preffered);
    formData.append("order", order);
    formData.append("description", description);

    selectedImages.forEach((image, index) => {
      formData.append(`selectedImages`, image);
    });

    newImages.forEach((image, index) => {
      formData.append(`newImages`, image);
    });

    removedImages.forEach((image, index) => {
      formData.append(`removedImages[${index}]`, image);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJwt}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(
        `${serverUrl}/edit-product/${selectedProduct.productId}`,
        requestOptions
      );
      if (response.ok) {
        document.getElementById("productName").value = "";
        document.getElementById("productPrice").value = "";
        document.getElementById("discount").value = "";
        document.getElementById("productSKU").value = "";
        document.getElementById("volume").value = "";
        document.getElementById("alcohol").value = "";
        document.getElementById("country").value = "";
        document.getElementById("category").value = "";
        document.getElementById("temperature").value = "";
        document.getElementById("associations").value = "";
        document.getElementById("productDescription").value = "";
        document.getElementById("order").value = "";
        setSelectedImages([]);
        setNewImages([]);
        setRemovedImages([]);
        alert("Produs actualizat cu succes!");
      } else {
        alert("Actualizarea a esuat");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  useEffect(() => {
    fetchCategories();
    setFetchedCategories(true);
  }, [setCategories]);

  useEffect(() => {
    // After reordering, separate the selectedImages and newImages based on their indices
    const numSelectedImages = selectedImages.length;
    const updatedSelectedImages = images.slice(0, numSelectedImages);
    const updatedNewImages = images.slice(numSelectedImages);

    setSelectedImages(updatedSelectedImages);
    setNewImages(updatedNewImages);
  }, [images]);

  return (
    <div className={classes.cartBackground}>
      <div style={{ height: navbarHeight }}></div>
      <div>
        <button onClick={handleBackToView}>
          <ArrowBack />
          <br></br> Inapoi la vizualizare produse
        </button>
      </div>
      <div className={classes.content}>
        <h2>Editare produs</h2>
        <br />
        <div>
          <div>Denumire</div>
          <input
            id="productName"
            type="text"
            defaultValue={selectedProduct.name}
          />
          <div>Pret</div>
          <input
            id="productPrice"
            prefix=""
            defaultValue={selectedProduct.price}
            min={1}
          />

          <div>Discount (procente)</div>
          <input
            id="discount"
            type="text"
            defaultValue={selectedProduct.discount}
          />
          <div>Nr Unitati</div>
          <input
            id="productSKU"
            type="text"
            defaultValue={selectedProduct.sku}
          />
          <div>Volum (L)</div>
          <input
            id="volume"
            type="text"
            defaultValue={selectedProduct.volume}
          />
          <div>Alcool (%)</div>
          <input
            id="alcohol"
            type="text"
            defaultValue={selectedProduct.alcohol}
          />
          <div>Tara de Origine</div>
          <input
            id="country"
            type="text"
            defaultValue={selectedProduct.country}
          />
          <div>Categorie</div>
          <select
            id="category"
            selected={selectedProduct.category}
            defaultChecked
          >
            {Object.keys(categories).map((category) => (
              <option
                value={categories[category].categoryName}
                key={categories[category].categoryId}
                selected={
                  categories[category].categoryName === selectedProduct.category
                }
              >
                {categories[category].categoryName}
              </option>
            ))}
          </select>
          <div>Temperatura de servire</div>
          <input
            id="temperature"
            type="text"
            defaultValue={selectedProduct.temperature}
          />
          <div>Asocieri</div>
          <input
            id="associations"
            type="text"
            defaultValue={selectedProduct.associations}
          />
          <div>Preferat (prima pagina)</div>
          {selectedProduct.preffered === "true" && (
            <input
              type="checkbox"
              id="preffered"
              defaultChecked={selectedProduct.preffered}
            />
          )}
          {selectedProduct.preffered === "false" && (
            <input type="checkbox" id="preffered" />
          )}
          <div>ORDINE AFISARE</div>
          <input
            id="order"
            type="text"
            placeholder="1,2,3 etc"
            defaultValue={selectedProduct.order}
          ></input>
          <div>Descriere</div>
          <textarea
            style={{ width: "80%", height: "300px" }}
            id="productDescription"
            defaultValue={selectedProduct.description}
          />
          <br />
          <br />
          <h3>Imagini selectate:</h3>
          <div>
            Pentru a schimba ordinea, mai intai sterge toate pozele apoi
            ordoneaza-le pe cele proaspat adaugate!
          </div>
          <div className={classes.imagesContainer}>
            <br></br>
            <br></br>
            <DragDropContext onDragEnd={handleImageReorder}>
              <Droppable droppableId="selected-images">
                {(provided) => (
                  <div
                    className={classes.selectedImagesContainer}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedImages &&
                      selectedImages.length > 0 &&
                      selectedImages.map((imageUrl, index) => (
                        <Draggable
                          key={index}
                          draggableId={`image-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.selectedImageContainer}
                            >
                              <img
                                src={
                                  typeof imageUrl === "string"
                                    ? `${uploadUrl}${imageUrl}`
                                    : URL.createObjectURL(imageUrl)
                                }
                                alt={`Image ${index + 1}`}
                                className={classes.selectedImage}
                              />
                              <button
                                className={classes.buttonRemove}
                                onClick={() => handleRemoveExistingImage(index)}
                              >
                                X
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {newImages &&
                      newImages.length > 0 &&
                      newImages.map((imageUrl, index) => (
                        <Draggable
                          key={index + selectedImages.length}
                          draggableId={`image-${index + selectedImages.length}`}
                          index={index + selectedImages.length}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.selectedImageContainer}
                            >
                              <img
                                src={
                                  typeof imageUrl === "string"
                                    ? `${uploadUrl}${imageUrl}`
                                    : URL.createObjectURL(imageUrl)
                                }
                                alt={`Image ${
                                  index + selectedImages.length + 1
                                }`}
                                className={classes.selectedImage}
                              />
                              <button
                                className={classes.buttonRemove}
                                onClick={() =>
                                  handleRemoveNewImage(
                                    index + selectedImages.length
                                  )
                                }
                              >
                                X
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <br></br>
            <br></br>
            <label className={classes.buttonAddImage} htmlFor="addFiles">
              Adauga imagini
            </label>
            <input
              type="file"
              id="addFiles"
              onChange={handleAddMorePhotos}
              multiple
              accept="image/png, image/gif, image/jpeg"
              aria-label="Adauga mai multe poze"
              style={{ display: "none" }}
            />
          </div>
          <br></br>
          <br></br>
          <button onClick={handleAddProduct}>Actualizeaza produsul</button>
          <button onClick={handleBackToView}>Inapoi</button>
        </div>
      </div>
      <br />
    </div>
  );
};
