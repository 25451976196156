import { makeStyles } from "@mui/styles";
import authBackground from "../../../media/shop-background.avif";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  contentBackgroundHolder: {
    backgroundImage: `url(${authBackground})`,
    height: "92.8vh",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    color: "#bcab79",
  },
  centeredContent: {
    textAlign: "center",
  },
  innerContentBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "100%",
  },
  iconHolder: {
    width: "10vw",
    margin: "auto",
    "@media(max-width:768px)": {
      width: "40vw",
    },
  },
  pageTextColor: {
    color: "#bcab79",
    width: "95vw",
    margin: "auto",
  },
  spaceBeforeContent: {
    minHeight: "23vh",
  },
  wrongField: {
    border: "2px solid red",
  },
}));
