import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { loginMessages } from "../../../consts/loginMessages";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthProvider";
import CustomErrorMessage from "../../Error/CustomErrorMessage";
import { PSDark, PSGold, PSRed } from "../../../consts/styleConsts";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import { emailRegexTest } from "../../../consts/functions";
import { serverUrl } from "../../../consts/serverUrl";

export const Auth = (props) => {
  const { navbarHeight, setPreviousPage } = props;
  const { user, isAuthenticated } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    setPreviousPage("");
  });
  const authCaseRender = () => {
    if (user === null || user === undefined || !isAuthenticated()) {
      return <UserAuth navbarHeight={navbarHeight} />;
    } else if (user) return <UserPage navbarHeight={navbarHeight} />;
  };

  return <main className={classes.pageContent}>{authCaseRender()}</main>;
};

/**
 * The UserAuth component is the component that is displayed when the user is not logged in.
 * @returns The login form.
 */
const UserAuth = (props) => {
  const { navbarHeight } = props;
  const { user, login, processLogin } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginResponseMessage, setLoginResponseMessage] = React.useState("");
  const [loginError, setLoginError] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    /** @type {{ preventDefault: () => void; }} */ event
  ) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    let email, password, rememberMe;

    const mailElement = document.getElementById("mail");
    const passwordElement = document.getElementById("password");
    const rememberMeElement = document.getElementById("rememberMe");

    if (mailElement instanceof HTMLInputElement) email = mailElement.value;
    if (passwordElement instanceof HTMLInputElement)
      password = passwordElement.value;
    if (rememberMeElement instanceof HTMLInputElement)
      rememberMe = rememberMeElement.checked;

    const loginResponse = await login(email, password, rememberMe);
    // @ts-ignore
    const loginMessage = loginResponse.message;
    // @ts-ignore
    const loginUserInfo = loginResponse.userInfo;

    if (loginMessage === loginMessages.noUser) {
      setLoginResponseMessage(loginMessages.noUser);
      setLoginError(true);
    } else if (loginMessage === loginMessages.wrongPassword) {
      setLoginResponseMessage(loginMessages.wrongPassword);
      setLoginError(true);
    } else if (loginMessage === loginMessages.emailNotVerified) {
      setLoginResponseMessage(loginMessages.emailNotVerified);
      setLoginError(true);
    } else if (
      loginMessage === loginMessages.successUser ||
      loginMessage === loginMessages.successAdmin
    ) {
      setLoginResponseMessage(loginMessages.successUser);
      setLoginError(false);

      // @ts-ignore
      processLogin(loginUserInfo, loginResponse.jwtToken, rememberMe);
    }
  };

  const handleSignUp = () => {
    navigate("/register");
  };
  return (
    <div className={classes.contentBackgroundHolder}>
      <div className={classes.innerContentBackground}>
        <div
          style={{
            height: navbarHeight,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
        ></div>
        {!forgotPassword ? (
          <>
            <h1>Intră în cont</h1>
            <div>Adresa de mail</div>
            <Input
              style={{
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                marginBottom: "10px",
                color: PSGold,
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "13rem",
                height: "2.5rem",
              }}
              disableUnderline
              type="mail"
              id="mail"
              // @ts-ignore
              defaultValue={user && user.rememberMe ? user.email : ""}
            />
            {loginError && loginResponseMessage === loginMessages.noUser && (
              <CustomErrorMessage message={"Utilizator inexistent."} />
            )}
            {loginError &&
              loginResponseMessage === loginMessages.emailNotVerified && (
                <CustomErrorMessage
                  message={"Adresa de mail nu a fost verificată."}
                />
              )}
            <br></br>
            <div>Parola</div>
            <OutlinedInput
              style={{
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                marginBottom: "10px",
                color: PSGold,
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "13rem",
                height: "2.5rem",
              }}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment style={{ height: "2.5rem" }} position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ marginRight: "1%" }}
                  >
                    {showPassword ? (
                      <VisibilityOff style={{ color: PSGold }} />
                    ) : (
                      <Visibility style={{ color: PSGold }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              id="password"
            ></OutlinedInput>
            {loginError &&
              loginResponseMessage === loginMessages.wrongPassword && (
                <CustomErrorMessage
                  message={"Parola introdusă nu este corectă."}
                ></CustomErrorMessage>
              )}
            <br></br>
            <br></br>
            <input type="checkbox" value="lsRememberMe" id="rememberMe" />{" "}
            <label htmlFor="rememberMe">Ramai conectat</label>
            <br></br>
            <br></br>
            <Button
              style={{
                backgroundColor: PSGold,
                color: PSDark,
                marginBottom: "10px",
              }}
              onClick={handleLogin}
            >
              Login
            </Button>
            <br />
            <Button
              style={{ backgroundColor: PSGold, color: PSDark }}
              onClick={handleSignUp}
            >
              Inregistrare
            </Button>
            <br />
            <br />
            <div
              style={{ textDecoration: "underline" }}
              onClick={() => setForgotPassword(true)}
            >
              Ai uitat parola?
            </div>
            <br />
            <br />
          </>
        ) : (
          <>
            <ForgotPassword
              toggleForgotPassword={() => setForgotPassword(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};

/**
 * The UserPage component is the component that is displayed when the user is logged in.
 * @returns The UserPage component, which is displayed when the user is logged in.
 */
const UserPage = (props) => {
  const { navbarHeight } = props;
  const { user, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleShop = () => {
    navigate("/shop");
  };
  const handleOrderHistory = () => {
    navigate("/order-history");
  };
  if (user === null || user === undefined || !isAuthenticated) return null;
  // TODO: Add cool holders for shop, orders history, etc.
  // TODO: Add favorite products
  return (
    <div className={classes.contentBackgroundHolder}>
      <div className={classes.innerContentBackground}>
        <div
          style={{
            height: navbarHeight,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
        ></div>
        {/* @ts-ignore */}
        <h1>Salut, {user.firstName}!</h1>
        <br />
        <Button
          style={{ backgroundColor: PSGold, color: PSDark }}
          onClick={handleShop}
        >
          Magazin
        </Button>
        <br />
        <br />
        <Button
          style={{ backgroundColor: PSGold, color: PSDark }}
          onClick={handleOrderHistory}
        >
          Istoric comenzi
        </Button>
        <br />
        <br />
        <Button
          style={{ backgroundColor: PSRed, color: PSDark }}
          onClick={logout}
        >
          Deconectare
        </Button>
        <br />
        <br />
      </div>
    </div>
  );
};

const ForgotPassword = ({ toggleForgotPassword }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [wrongEmail, setWrongEmail] = useState(false);
  const [forgotPasswordResponse, setForgotPasswordResponse] = useState("");

  useEffect(() => {
    if (email !== "") {
      if (!emailRegexTest(email)) {
        setWrongEmail(true);
      } else {
        setWrongEmail(false);
      }
    }
  });

  const handleForgotPassword = async () => {
    if (email === "" || !emailRegexTest(email)) {
      setWrongEmail(true);
      return;
    }

    const forgotPasswordResponse = await fetch(`${serverUrl}/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }).then((res) => res.json());

    // @ts-ignore
    const forgotPasswordMessage = forgotPasswordResponse.message;
    setForgotPasswordResponse(forgotPasswordMessage);
  };

  return (
    <div className={classes.contentBackgroundHolder}>
      <div className={classes.innerContentBackground}>
        <h1>Recuperare parolă</h1>
        <div>Adresa de mail</div>
        <Input
          style={{
            border: `1px solid ${PSGold}`,
            borderRadius: "10px",
            marginBottom: "10px",
            color: PSGold,
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "13rem",
            height: "2.5rem",
          }}
          disableUnderline
          type="mail"
          id="mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {wrongEmail && (
          <CustomErrorMessage
            message={"Introduceți o adresă de mail validă"}
          ></CustomErrorMessage>
        )}
        <br />
        <Button
          style={{
            backgroundColor: PSGold,
            color: PSDark,
            marginBottom: "10px",
          }}
          onClick={handleForgotPassword}
        >
          Recuperează parola
        </Button>
        <br />
        <Button
          style={{
            backgroundColor: PSGold,
            color: PSDark,
            marginBottom: "10px",
          }}
          onClick={() => toggleForgotPassword()}
        >
          Înapoi
        </Button>
        <br />
        {forgotPasswordResponse === "Success" && (
          <div style={{ color: PSGold }}>
            Un email de resetare a fost trimis la adresa de mail specificată.
          </div>
        )}
        {forgotPasswordResponse === "No such user" && (
          <>
            <CustomErrorMessage
              message={"Nu există niciun cont asociat acestei adrese de mail."}
            ></CustomErrorMessage>
            <br />
            <Button
              style={{ backgroundColor: PSGold, color: PSDark }}
              onClick={() => navigate("/register")}
            >
              Mergi la înregistrare
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
