import React, { useState, useEffect } from "react";
import { WelcomePage } from "../WelcomePage/WelcomePage";
import { Navbar } from "../Navbar/Navbar";
import { Cart } from "../Cart/Cart";
import { Auth } from "../UserPage/Auth/Auth";
import { ProductPage } from "../ProductPage/ProductPage";
import { PaymentConfirmation } from "../Checkout/PaymentConfirmation";
import { Button, Modal } from "@mui/material";
import { AdminPage } from "../AdminPage/AdminPage";
import { ManageProducts } from "../ManageProducts/ManageProducts";
import { AddProduct } from "../AddProduct/AddProduct";
import { serverUrl } from "../../consts/serverUrl";
import { Footer } from "../Footer/Footer";
import { ShopPage } from "../ShopPage/ShopPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { EditProduct } from "../EditProduct/EditProduct";
import { Shipping } from "../Shipping/Shipping";
import { HowTo } from "../HowTo/HowTo";

import { retrieveUserInfo } from "../../consts/functions";

import useStyles from "./styles";

import "typeface-montserrat";
import SignUp from "../UserPage/SignUp/SignUp";
import { MailConfirmation } from "../MailConfirmation/MailConfirmation";
import { useAuth } from "../../contexts/AuthProvider";
import { OrderHistory } from "../UserPage/OrderHistory/OrderHistory";
import { ResetPassword } from "../UserPage/ResetPassword/ResetPassword";
import { PSDark, PSGold } from "../../consts/styleConsts";

const LandingPage = () => {
  const { setUserCart } = useAuth();
  const [page, setPage] = useState("Loading");
  const [userInfo, setUserInfo] = useState(retrieveUserInfo());
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [openModal, setOpenModal] = useState(
    !localStorage.getItem("ageModalShown") ? true : false
  );
  const [navbarHeight, setNavbarHeight] = useState(
    document.getElementById("appNavbar")?.scrollHeight
  );
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [previousShopScroll, setPreviousShopScroll] = useState(0);
  const [previousShopCategory, setPreviousShopCategory] = useState("All");
  const [previousPage, setPreviousPage] = useState("Main");

  const location = useLocation();
  const classes = useStyles();

  const fetchProducts = async () => {
    const data = await fetch(`${serverUrl}/products`).then((res) => res.json());
    setProducts(data);
  };

  const handleCloseAgeModal = () => {
    localStorage.setItem("ageModalShown", "true");
    setOpenModal(false);
  };

  useEffect(() => {
    if (location.pathname === "/product") {
      setPage("product");
    } else if (location.pathname === "/auth") {
      setPage("auth");
    } else if (location.pathname === "/shop") {
      setPage("shop");
      fetchProducts();
    } else if (location.pathname === "/descopera") {
      setPage("howTo");
    } else {
      setPage("Main");
    }
  }, [location]);

  useEffect(() => {
    fetchProducts();
    setPage("Main");
    const navHeight = document.getElementById("appNavbar")?.scrollHeight;
    if (navHeight !== undefined) setNavbarHeight(navHeight);
  }, [setProducts, setUserCart, setPage, setNavbarHeight]);

  const mainPageMapping = {
    AddProduct: (
      <AddProduct
        setPage={setPage}
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></AddProduct>
    ),
    AdminPage: (
      <AdminPage
        page={page}
        setPage={setPage}
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></AdminPage>
    ),
    Auth: (
      <Auth
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></Auth>
    ),
    Cart: (
      <Cart
        page={page}
        setPage={setPage}
        products={products}
        setSelectedProduct={setSelectedProduct}
        navbarHeight={navbarHeight}
        discountPercentage={discountPercentage}
        setDiscountPercentage={setDiscountPercentage}
        setPreviousPage={setPreviousPage}
      ></Cart>
    ),
    Checkout: (
      <PaymentConfirmation
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></PaymentConfirmation>
    ),
    EditProduct: (
      <EditProduct
        setPage={setPage}
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></EditProduct>
    ),
    HowTo: <HowTo setPreviousPage={setPreviousPage} />,
    Loading: <div>Loading...</div>,
    ManageProducts: (
      <ManageProducts
        setPage={setPage}
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></ManageProducts>
    ),
    MailConfirmation: (
      <MailConfirmation navbarHeight={navbarHeight}></MailConfirmation>
    ),
    Main: (
      <div>
        <Modal
          open={openModal}
          onClose={() => handleCloseAgeModal}
          className={classes.modal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div className={classes.modalContent}>
            <img
              src="logo192.avif"
              alt="logo"
              className={classes.modalLogo}
            ></img>
            <h2 id="modal-title">Bine ai venit!</h2>
            <p id="modal-description" style={{ margin: "1rem" }}>
              Pentru a avea parte de cea mai fină experiență Prosecco trebuie să
              confirmi că ai cel puțin 18 ani!
            </p>
            <div>
              <Button
                style={{ backgroundColor: PSGold, color: PSDark }}
                onClick={handleCloseAgeModal}
                className={classes.modalButton}
              >
                Am peste 18 ani
              </Button>
            </div>
          </div>
        </Modal>
        <WelcomePage
          productList={products}
          setPage={setPage}
          setSelectedProduct={setSelectedProduct}
          navbarHeight={navbarHeight}
          setPreviousPage={setPreviousPage}
        ></WelcomePage>
      </div>
    ),
    OrderHistory: (
      <OrderHistory
        navbarHeight={navbarHeight}
        allProducts={products}
      ></OrderHistory>
    ),
    PaymentConfirmation: (
      <PaymentConfirmation
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      ></PaymentConfirmation>
    ),
    ProductPage: (
      <ProductPage
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        navbarHeight={navbarHeight}
        setPreviousPage={setPreviousPage}
      />
    ),
    ResetPassword: <ResetPassword navbarHeight={navbarHeight} />,
    Shipping: (
      <Shipping
        page={page}
        setPage={setPage}
        products={products}
        userInfo={userInfo}
        navbarHeight={navbarHeight}
        discountPercentage={discountPercentage}
        setPreviousPage={setPreviousPage}
      ></Shipping>
    ),
    Shop: (
      <ShopPage
        page={page}
        setPage={setPage}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        productList={products}
        navbarHeight={navbarHeight}
        previousShopScroll={previousShopScroll}
        setPreviousShopScroll={setPreviousShopScroll}
        previousPage={previousPage}
        previousShopCategory={previousShopCategory}
        setPreviousShopCategory={setPreviousShopCategory}
      ></ShopPage>
    ),
    SignUp: <SignUp navbarHeight={navbarHeight}></SignUp>,
  };

  return (
    <div className={classes.content}>
      <Navbar />
      <br />
      <Routes>
        <Route path="/" element={mainPageMapping.Main} />
        <Route
          path="/admin"
          element={
            page === "AdminPage"
              ? mainPageMapping.AdminPage
              : page === "ManageProducts"
              ? mainPageMapping.ManageProducts
              : page === "AddProduct"
              ? mainPageMapping.AddProduct
              : page === "EditProduct"
              ? mainPageMapping.EditProduct
              : mainPageMapping.AdminPage
          }
        />
        <Route path="/home" element={mainPageMapping.Main} />
        <Route
          path="/produs/:productName"
          element={mainPageMapping.ProductPage}
        ></Route>
        <Route path="/shop" element={mainPageMapping.Shop} />
        <Route path="/auth" element={mainPageMapping.Auth} />
        <Route
          path="/reset-password/:token/:email"
          element={mainPageMapping.ResetPassword}
        ></Route>
        <Route path="/order-history" element={mainPageMapping.OrderHistory} />
        <Route path="/cart" element={mainPageMapping.Cart} />
        <Route path="/cart/shipping" element={mainPageMapping.Shipping} />
        <Route path="/descopera/:chapter" element={mainPageMapping.HowTo} />
        <Route path="/register" element={mainPageMapping.SignUp} />
        <Route
          path="/payment-confirmation/:orderId"
          element={mainPageMapping.PaymentConfirmation}
        />
        <Route
          path="/mail-confirmation/:token/:email"
          element={mainPageMapping.MailConfirmation}
        ></Route>
        <Route
          path="/mail-confirmation-failure"
          element={mainPageMapping.MailConfirmation}
        ></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
};
export default LandingPage;
