import { makeStyles } from "@mui/styles";
import shopBackground from "../../media/shop-background.avif";

export default makeStyles((theme) => ({
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  root: {
    textAlign: "center",
    width: "100vw",
    flexGrow: 1,
    backgroundColor: "#0a0808",
    color: "#bcab79",
    backgroundImage: `url(${shopBackground})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  chapterTitleHolder: {
    fontSize: "1rem",
    padding: "1%",
    backgroundColor: "rgb(0,0,0,0.9)",
  },
  chapterAboutHolder: {
    textAlign: "justify",
    fontSize: "1.2rem",
    padding: "5% 10% 5% 10%",
    backgroundColor: "rgb(0,0,0,0.9)",
  },
  chapterImg: {
    height: "75vh",
    borderRadius: "10px",
  },
  "@media (max-width: 768px)": {
    chapterImg: {
      height: "auto",
      width: "90vw",
    },
  },
}));
