import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  root: {
    maxWidth: "100%",
    minWidth: "35vh",
    borderRadius: "10px",
  },
  productCard: {
    width: "22vw",
    margin: "2vw auto",
    backgroundColor: "#0a0808",
    border: "1px solid #bcab79",
  },
  media: {
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    aspectRatio: "3 / 4",
    objectFit: "contain",
    backgroundImage: "black",
    margin: "auto",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardContent: {
    display: "flex",
    // justifyContent: "space-between",
    textAlign: "center",
    margin: "auto",
    alignItems: "center",
    alignContent: "center",
    width: "100%",
    flexDirection: "column",
    marginBottom: "1rem",
  },
  productName: {
    fontFamily: "Montserrat, sans-serif !important",
    width: "100%",
    aspectRatio: "1 / 0.4",
  },
  "@media(max-width: 1200px)": {
    productCard: {
      width: "30vw",
    },
  },
  "@media(max-width: 970px)": {
    productCard: {
      width: "45vw",
    },
  },
  "@media(max-width: 900px)": {
    productCard: {
      width: "40vw",
      // aspectRatio: "9 / 20",
    },
  },
  "@media(max-width: 700px)": {
    productCard: {
      width: "45vw",
    },
  },
  "@media(max-width: 600px)": {
    productCard: {
      width: "60vw",
    },
  },
  "@media(max-width: 500px)": {
    productCard: {
      width: "70vw",
      // aspectRatio: "1 / 2.184",
    },
  },
}));
