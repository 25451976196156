import { makeStyles } from "@mui/styles";
// @ts-ignore
import shopBackground from "../../media/shop-background.avif";

export default makeStyles(() => ({
  root: {
    textAlign: "center",
    width: "100vw",
    flexGrow: 1,
    backgroundColor: "#0a0808",
    color: "#bcab79",
    backgroundImage: `url(${shopBackground})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  loaderBody: {
    backgroundColor: "#0a0808",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: 0,
  },
  loaderImage: {
    animation: "$spin 1s linear infinite",
    width: "15vw",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },

  modal: {
    display: "flex",
    // position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "#bcab79",
    width: "100vw",
    margin: "auto",
  },

  modalContent: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(10,8,8,0.85)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
    outline: "none",
    fontFamily: "Montserrat, sans-serif",
    display: "flex",
    margin: "auto",
  },
  carouselHeightModal: {
    fontFamily: "Montserrat, sans-serif",
    // backgroundColor: "black",
    // margin: "auto",
    // float: "left",
    width: "100%",
    maxHeight: "77vh",
    alignItems: "center",
  },
  carouselHeight: {
    fontFamily: "Montserrat, sans-serif",
    height: "72.2vh",
    backgroundColor: "black",
    margin: "auto",
    float: "left",
    width: "100%",
    borderRadius: "8px",
    border: "2px solid #bcab79",
  },
  productInfoHolder: {
    textAlign: "center",
    maxWidth: "100%",
    minHeight: "73vh",
  },
  productCarouselHolder: {
    marginTop: "2vh",
    marginLeft: "1vw",
    width: "35vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundClip: "padding-box",
    float: "left",
  },
  productAboutHolder: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    marginTop: "2vh",
    marginRight: "1vw",
    backgroundColor: "rgb(0,0,0,0.9)",
    backgroundClip: "padding-box",
    width: "60vw",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    height: "72.2vh",
    fontSize: "2vh",
    borderRadius: "8px",
    overflowY: "scroll",
  },
  productCharsRightHolder: {
    marginTop: "2vh",
    marginRight: "1%",
    backgroundColor: "rgb(0,0,0,0.9)",
    backgroundClip: "padding-box",
    width: "60vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1%",
    float: "right",
    textAlign: "justify",
    height: "21vh",
    fontSize: "0.95rem",
    borderRadius: "8px",
    overflowY: "scroll",
  },
  productDescriptionHolder: {
    margin: "1vh 1vw 0vh 1vw",
    backgroundColor: "rgb(0,0,0,0.9)",
    backgroundClip: "padding-box",
    padding: "1vw",
    textAlign: "justify",
    width: "96%",
    fontSize: "2vh",
    justifyContent: "right",
    borderRadius: "8px",
  },
  productCharsHolder: {
    backgroundColor: "rgb(0,0,0,0.77)",

    padding: "1vw",
    textAlign: "left",
    maxWidth: "100%",
  },
  media: {
    height: "20vh",
    paddingTop: "56.25%",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  brHolder: {
    minHeight: "5vh",
  },
  smallBrHolder: {
    minHeight: "3vh",
  },
  content: {
    fontFamily: "Montserrat, sans-serif",
    flexGrow: 1,
  },
  quantityInput: {
    alignItems: "center",
    color: "white",
  },

  quantityBtn: {
    backgroundColor: "#ccc",
    color: "#333",
    border: "none",
    padding: "0.5rem 1rem",
    cursor: "pointer",
  },

  quantityField: {
    border: "1px solid #0a0808",
    padding: "0.5rem",
    width: "2.5rem",
    textAlign: "center",
    color: "#0a0808",
  },
  btnAddToCart: {
    backgroundColor: "#0a0808",
    color: "#bcab79 !important",
    border: "1px solid #bcab79",
    borderRadius: "7px",
    "&:hover": {
      border: "1px solid #0a0808",
      backgroundColor: "#bcab79",
      color: "#0a0808 !important",
    },
  },
  productCartBtn: {
    color: "inherit",
  },
  "@media (max-width: 820px)": {
    loaderImage: { width: "40vw" },
    carouselHeight: {
      fontFamily: "Montserrat, sans-serif",
      height: "none",
      width: "98vw",
      aspectRatio: "3 / 4",
    },
    productInfoHolder: {
      textAlign: "center",
      maxWidth: "98vw",
      height: "auto",
    },
    productCarouselHolder: {
      float: "none",
      width: "98vw",
      justifyContent: "center",
      alignItems: "center",
      padding: "1vw",
      marginLeft: "auto",
    },
    productAboutHolder: {
      marginLeft: "1vw",
      marginRight: "1vw",
      width: "96vw",
      height: "auto",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      padding: "1vw",
      float: "none",
      fontSize: "0.75rem",
    },
    productCharsRightHolder: {
      marginLeft: "1vw",
      marginRight: "1vw",
      float: "none",
      width: "96vw",
      height: "auto",
      fontSize: "0.9rem",
    },
    productDescriptionHolder: {
      fontSize: "0.9rem",
      width: "90%",
      padding: "4vw",
    },
  },
  "@media (width: 1024px) and (height: 600px)": {
    productAboutHolder: {
      fontSize: "0.65rem",
    },
    productCharsRightHolder: {
      fontSize: "0.65rem",
    },
    productDescriptionHolder: {
      fontSize: "0.65rem",
    },
  },
}));
