import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    display: "contents",
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  qtyInput: {
    width: "2vw",
    "@media (max-width: 768px)": {
      width: "8vw",
    },
  },
  cartBackground: {
    backgroundColor: "white",
  },
  continueButton: { backgroundColor: "green" },
  cartMessage: { fontWeight: "bold" },
  cartColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      flex: "0 1 calc(70%)",
      flexDirection: "column",
    },
  },
  cartColumn: {
    border: "1px solid black",
    flex: "0 1 calc(100%)",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
    "@media (max-width: 480px)": {
      flex: "1 1 100%",
    },
  },
  shippingCartColumn: {
    flex: "0 1 calc(100%)",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
    "@media (max-width: 480px)": {
      flex: "1 1 100%",
    },
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  wrongField: {
    border: "2px solid red",
  },
  loaderBody: {
    backgroundColor: "#0a0808",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "95vh",
    margin: 0,
    color: "#bcab79",
  },
  loaderImage: {
    animation: "$spin 1s linear infinite",
    width: "15vw",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  "@media (max-width: 768px)": {
    loaderImage: { width: "40vw" },
  },
}));
