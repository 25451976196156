import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { serverUrl } from "../../consts/serverUrl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ArrowBack } from "@mui/icons-material";

export const AddProduct = (props) => {
  const { setPage, navbarHeight, setPreviousPage } = props;
  const classes = useStyles();
  const [selectedImages, setSelectedImages] = useState([]);
  const [categories, setCategories] = useState({});

  const handleBackToManage = () => {
    setPage("ManageProducts");
  };

  const handleImageReorder = (result) => {
    if (!result.destination) return; // Return if the image is dropped outside of a droppable area
    const items = Array.from(selectedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedImages(items);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleAddMorePhotos = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  const fetchCategories = async () => {
    let localCategories = {};
    try {
      localCategories = await fetch(`${serverUrl}/categories`).then((res) =>
        res.json()
      );
      if (localCategories.length > 0) setCategories(localCategories);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleClickBack = () => {
    setPage("ManageProducts");
  };

  const handleAddProduct = async () => {
    const adminJwt = localStorage.getItem("proseccoshop_admin_token") || "";
    if (!adminJwt) {
      alert("Token de admin inexistent!");
      return;
    }

    const name = document.getElementById("productName").value;
    const price = parseFloat(
      document.getElementById("productPrice").value.replace(",", "")
    );
    const discount = document.getElementById("discount").value;
    const sku = document.getElementById("productSKU").value;
    const volume = document.getElementById("volume").value;
    const alcohol = document.getElementById("alcohol").value;
    const country = document.getElementById("country").value;
    const category = document.getElementById("category").value;
    const temperature = document.getElementById("temperature").value;
    const associations = document.getElementById("associations").value;
    const description = document.getElementById("productDescription").value;
    const preffered = document.getElementById("preffered").checked.toString();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("discount", discount);
    formData.append("sku", sku);
    formData.append("volume", volume);
    formData.append("alcohol", alcohol);
    formData.append("country", country);
    formData.append("category", category);
    formData.append("temperature", temperature);
    formData.append("associations", associations);
    formData.append("preffered", preffered);
    formData.append("description", description);

    selectedImages.forEach((image) => {
      formData.append("images", image);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJwt}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(`${serverUrl}/add-product`, requestOptions);
      if (response.ok) {
        document.getElementById("productName").value = "";
        document.getElementById("productPrice").value = "";
        document.getElementById("discount").value = "";
        document.getElementById("productSKU").value = "";
        document.getElementById("volume").value = "";
        document.getElementById("alcohol").value = "";
        document.getElementById("country").value = "";
        document.getElementById("category").value = "";
        document.getElementById("temperature").value = "";
        document.getElementById("associations").value = "";
        document.getElementById("productDescription").value = "";
        setSelectedImages([]);
        alert("Produs adaugat cu succes!");
      } else {
        alert("Adaugarea a esuat");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [setCategories]);

  return (
    <div className={classes.cartBackground}>
      <div style={{ height: navbarHeight }}></div>
      <button onClick={handleBackToManage}>
        <ArrowBack></ArrowBack>
        <br></br>Inapoi la Product Manager Page
      </button>
      <div className={classes.content}>
        <h2>Adaugare produs</h2>
        <br />
        <div>
          <div>Denumire</div>
          <input id="productName" type="text" />
          <div>Pret</div>
          <input id="productPrice" prefix="" defaultValue={0} min={1} />
          <div>Discount (procente)</div>
          <input id="discount" type="text" />
          <div>Nr Unitati</div>
          <input id="productSKU" type="text" />
          <div>Volum (L)</div>
          <input id="volume" type="text" />
          <div>Alcool (%)</div>
          <input id="alcohol" type="text" />
          <div>Tara de Origine</div>
          <input id="country" type="text" />
          <div>Categorie</div>
          <select id="category">
            {Object.keys(categories).map((category) => (
              <option
                value={categories[category].categoryName}
                key={categories[category].categoryId}
              >
                {categories[category].categoryName}
              </option>
            ))}
          </select>
          <div>Temperatura de servire</div>
          <input id="temperature" type="text" />
          <div>Asocieri</div>
          <input id="associations" type="text" />
          <div>Preferat (prima pagina)</div>
          <input type="checkbox" id="preffered" />
          <div>Descriere</div>
          <textarea
            style={{ width: "80%", height: "300px" }}
            id="productDescription"
          />
          <br />
          <br />
          <div>Imagini selectate:</div>

          <div className={classes.imagesContainer}>
            <br></br>
            <br></br>
            <DragDropContext onDragEnd={handleImageReorder}>
              <Droppable droppableId="selected-images">
                {(provided) => (
                  <div
                    className={classes.selectedImagesContainer}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedImages.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.selectedImageContainer}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Image ${index + 1}`}
                              className={classes.selectedImage}
                            />
                            <button
                              className={classes.buttonRemove}
                              onClick={() => handleRemoveImage(index)}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <br></br>
            <br></br>
            <label className={classes.buttonAddImage} htmlFor="addFiles">
              Adauga imagini
            </label>
            <input
              type="file"
              id="addFiles"
              onChange={handleAddMorePhotos}
              multiple
              accept="image/png, image/gif, image/jpeg"
              aria-label="Adauga mai multe poze"
              style={{ display: "none" }}
            />
          </div>
          <br></br>
          <br></br>
          <button onClick={handleAddProduct}>Adauga produsul</button>
          <button onClick={handleClickBack}>Inapoi</button>
        </div>
      </div>
      <br />
    </div>
  );
};
