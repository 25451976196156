import React from "react";
import useStyles from "./styles";
import { useMediaQuery } from "react-responsive";

export const WelcomePageVideo = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <div className={classes.blackBackground}>
      <video
        width="100%"
        height={isMobile ? "720rem" : "875rem"}
        autoPlay
        playsInline
        loop
        muted
        // controls
        style={{ objectFit: "cover" }}
      >
        <source src="landingvideo.mp4" type="video/mp4" />
      </video>
    </div>
  );
};
