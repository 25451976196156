import { makeStyles } from "@mui/styles";
import authBackground from "../../../media/shop-background.avif";

export default makeStyles((_theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    minHeight: "83.7vh",
  },
  root: {
    flexGrow: 1,
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  brHolder: {
    minHeight: "5vh",
  },
  ordersGridContainer: {
    width: "95vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
  },
  backgroundDarkHolderOrders: {
    height: "auto",
    minHeight: "75vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  spaceBeforeContent: {
    minHeight: "25vh",
  },
  contentBackgroundHolder: {
    backgroundImage: `url(${authBackground})`,
    // height: "92.8vh",
    backgroundSize: "fixed",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    color: "#bcab79",
  },
  innerContentBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "100%",
  },
  pageContent: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#0a0808",
  },
  orderHistoryBackground: {
    backgroundImage: `url(${authBackground})`,
    // backgroundSize: "cover",
    width: "auto",
    // backgroundPosition: "0px 0px",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "#bcab79",
    maxWidth: "95vw",
    paddingLeft: "2%",
  },
  modalContent: {
    backgroundColor: "#0a0808",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    padding: 16,
    outline: "none",
    border: "1px 1px 1px 1px",
    borderColor: "#bcab79",
    borderStyle: "solid",
    borderRadius: "8px",
    fontFamily: "Montserrat, sans-serif",
    maxWidth: "70vw",
  },
  "@media (max-width: 768px)": {
    pageContent: {
      flexGrow: 1,
      textAlign: "center",
      fontFamily: "Montserrat, sans-serif",
      minHeight: "48vh",
    },
    backgroundDarkHolderOrders: {
      flexDirection: "column",
    },
  },
}));
