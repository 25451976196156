import React, { useEffect } from "react";
import { useAuth } from "../../../contexts/AuthProvider";
import { SpinnerBody } from "../../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { PSDark, PSGold, PSRed } from "../../../consts/styleConsts";
import { findProduct } from "../../Cart/Cart";
import { uploadUrl } from "../../../consts/uploadsUrl";
import { capitalizeFirstLetter } from "../../../consts/functions";
import { useMediaQuery } from "react-responsive";
import { serverUrl } from "../../../consts/serverUrl";
import ReactStars from "react-stars";

export const OrderHistory = (props) => {
  const { navbarHeight, allProducts } = props;
  const { user, getUserId } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    // @ts-ignore
    if (getUserId() === "guest") {
      navigate("/auth");
    }
  }, [user]);

  // @ts-ignore
  if (getUserId() === "waiting" || allProducts.length === 0) {
    return <SpinnerBody />;
  }

  return (
    <main className={classes.pageContent} style={{ flexGrow: 1 }}>
      <div style={{ height: ((navbarHeight || 0) * 1) / 2 }} />
      <OrdersContainer allProducts={allProducts} />
      <div className={classes.brHolder}></div>
    </main>
  );
};

const OrdersContainer = ({ allProducts }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();
  if (!user) throw new Error("User not found");
  if (typeof user !== "object") throw new Error("Invalid state for user");

  const handleGoToShop = () => {
    navigate("/shop");
  };

  // Users should always have an order history array

  return (
    <div className={classes.contentBackgroundHolder}>
      <div className={classes.innerContentBackground}>
        <h1>Istoricul comenzilor</h1>
        <div className={classes.backgroundDarkHolderOrders}>
          <Grid container className={classes.ordersGridContainer}>
            {/* @ts-ignore */}
            {user.orderHistory
              .sort((a, b) => b.orderId - a.orderId)
              .map((order) => (
                <Order order={order} allProducts={allProducts} />
              ))}
            {/* @ts-ignore */}
            {user.orderHistory.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2>Nu există comenzi de afișat</h2>
                <div>
                  <Button
                    style={{
                      backgroundColor: PSGold,
                      color: PSDark,
                      width: "10rem",
                    }}
                    onClick={handleGoToShop}
                  >
                    Mergi la magazin
                  </Button>
                </div>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

const Order = ({ order, allProducts }) => {
  const {
    getUserId,
    userCart,
    userCartQtyItems,
    userCartLineItems,
    setUserCart,
    setUserCartLineItems,
    setUserCartQtyItems,
  } = useAuth();
  const [detailedView, setDetailedView] = React.useState(false);
  const userId = getUserId();
  const navigate = useNavigate();
  const handleRepeatOrder = (order) => {
    const orderProductsList = Object.keys(order.order).filter(
      (key) => key !== "total_qty"
    );
    const availableProducts = orderProductsList.filter((productId) =>
      allProducts.map((product) => product.productId).includes(productId)
    );
    const unavailableProducts = orderProductsList.filter(
      (productId) =>
        !allProducts.map((product) => product.productId).includes(productId)
    );

    if (unavailableProducts.length > 0) {
      alert(
        "Unele produse din comanda nu mai sunt disponibile. Vor fi adăugate doar produsele disponibile."
      );
    }

    // Create local copies of the cart, line items, and quantity
    let localCartLineItems = { ...userCartLineItems };
    let localCartQtyItems = userCartQtyItems;
    let localCart = { ...userCart };

    for (const productId of availableProducts) {
      const orderProduct = order.order[productId];
      const product = findProduct(productId, allProducts);
      const qty = orderProduct.qty;

      // Update local cart and quantity
      localCartQtyItems += qty;
      localCartLineItems[product.productId] =
        (localCartLineItems[product.productId] || 0) + qty;
    }

    localCart[userId] = {
      ...localCart[userId],
      line_items: localCartLineItems,
      qty_items: localCartQtyItems,
    };

    // Set the state only once after updating everything
    setUserCartLineItems(localCartLineItems);
    setUserCartQtyItems(localCartQtyItems);
    setUserCart(localCart);

    localStorage.setItem("proseccoshop_user_cart", JSON.stringify(localCart));

    // Redirect to the cart page
    navigate("/cart");
  };

  return (
    <Grid item xs={10} lg={6} key={order._id}>
      <Card
        style={{
          margin: "5%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: PSDark,
          color: PSGold,
          padding: "1rem",
          border: `1px solid ${PSGold}`,
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {`Comanda ID${order.orderId}`}
          </Typography>
          <Divider color={PSGold} style={{ marginBottom: "1rem" }} />
          <GeneralOrderData order={order} />
          {detailedView && (
            <DetailedOrderData order={order} allProducts={allProducts} />
          )}
        </CardContent>
        <CardActions
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: PSGold,
              color: PSDark,
              margin: "auto auto 1rem auto",
            }}
            onClick={() => {
              setDetailedView(!detailedView);
            }}
          >
            {detailedView ? "Minimizează" : "Vezi detalii"}
          </Button>
          <Button
            style={{
              backgroundColor: PSGold,
              color: PSDark,
              margin: "auto",
            }}
            onClick={() => {
              handleRepeatOrder(order);
            }}
          >
            Adaugă la coș
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

const GeneralOrderData = ({ order }) => {
  const orderStatus = orderStatusMap[order.status];
  const productsList = Object.keys(order.order).filter(
    (key) => key !== "total_qty"
  );
  const uniqueProductsNumber = productsList.length;
  const totalQty = order.order.total_qty;

  const paymentMethod = Object.keys(order).includes("payment")
    ? order.payment
    : "card";
  const displayedPaymentMethod = orderPaymentMap[paymentMethod];
  const price = order.price;

  return (
    <>
      <Typography gutterBottom>
        {`Status: ${orderStatus ? capitalizeFirstLetter(orderStatus) : ""}`}
      </Typography>
      <Typography gutterBottom>{`Plasată: ${order.dateCreated}`}</Typography>
      {orderStatus === "expediată" && (
        <Typography gutterBottom>
          {`Expediată: ${order.dateShipped}`}
        </Typography>
      )}
      <Typography gutterBottom>
        {`Metoda de plată: ${
          displayedPaymentMethod
            ? capitalizeFirstLetter(displayedPaymentMethod)
            : ""
        }`}
      </Typography>
      <Typography
        gutterBottom
      >{`Produse comandate: ${uniqueProductsNumber}`}</Typography>
      <Typography
        gutterBottom
      >{`Cantitate totală: ${totalQty} buc.`}</Typography>
      <Typography
        variant="h6"
        fontWeight="bold"
        style={{ marginBottom: "1rem" }}
      >{`${price} RON`}</Typography>
    </>
  );
};

const DetailedOrderData = ({ order, allProducts }) => {
  const billingAddress = order.billing.billingAddress;
  const shippingAddress = order.shipping.shippingAddress;

  return (
    <>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Facturare
      </Typography>
      <Typography gutterBottom>{billingAddress}</Typography>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Livrare
      </Typography>
      <Typography gutterBottom>{shippingAddress}</Typography>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        marginBottom="1rem"
      >
        Produse
      </Typography>
      <DetailedOrderProducts order={order} allProducts={allProducts} />
    </>
  );
};

const DetailedOrderProducts = ({ order, allProducts }) => {
  const productsList = Object.keys(order.order).filter(
    (key) => key !== "total_qty"
  );

  return (
    <>
      {productsList.map((productId) => {
        const orderProduct = order.order[productId];

        return (
          <OrderProduct
            productId={productId}
            orderId={order.orderId}
            orderStatus={order.status}
            orderProduct={orderProduct}
            allProducts={allProducts}
          />
        );
      })}
    </>
  );
};

const OrderProduct = ({
  productId,
  orderId,
  orderStatus,
  orderProduct,
  allProducts,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const product = findProduct(productId, allProducts);
  const productAvailable = product && product !== "" ? true : false;
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);

  const handleToggleReviewModal = () => setReviewModalOpen(!reviewModalOpen);

  return (
    <>
      {reviewModalOpen && (
        <ReviewPopup
          productId={productId}
          orderId={orderId}
          allProducts={allProducts}
          openModal={reviewModalOpen}
          handleCloseModal={handleToggleReviewModal}
        />
      )}
      <div
        style={{
          border: `1px solid ${PSGold}`,
          borderRadius: "10px",
          padding: "1rem",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          marginBottom: "1rem",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        {productAvailable && (
          <AvailableProduct
            product={product}
            orderProduct={orderProduct}
            orderStatus={orderStatus}
            handleToggleReviewModal={handleToggleReviewModal}
          />
        )}
        {!productAvailable && <UnavailableProduct />}
      </div>
    </>
  );
};

const AvailableProduct = ({
  product,
  orderProduct,
  orderStatus,
  handleToggleReviewModal,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [qty, setQty] = React.useState(0);
  const [price, setPrice] = React.useState(0);

  useEffect(() => {
    if (orderProduct.qty && orderProduct.unit_price) {
      setQty(orderProduct.qty);
      setPrice(orderProduct.unit_price);
    }
  }, [orderProduct]);

  return (
    <>
      <div
        style={{
          width: isMobile ? "40%" : "15%",
          height: "100%",
          marginRight: isMobile ? "auto" : "1rem",
          display: "flex",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <img
          src={`${uploadUrl}${product.images[0]}`}
          width="100%"
          style={{ borderRadius: "10px" }}
          alt={`${product.name}`}
        ></img>
      </div>
      <div style={{ width: "85%", margin: "auto" }}>
        <Typography
          variant="h6"
          marginBottom="1rem"
          fontWeight="bold"
        >{`${product.name}`}</Typography>
        <Typography>{`Cantitate: ${qty}`}</Typography>
        <Typography marginBottom="1rem">{`Pret unitar: ${price} RON`}</Typography>
        <Button
          style={{
            backgroundColor: orderStatus === "confirmata" ? "grey" : PSGold,
            color: PSDark,
            margin: "auto",
          }}
          disabled={orderStatus === "confirmata"}
          onClick={handleToggleReviewModal}
        >
          Recenzie
        </Button>
        {orderStatus === "confirmata" && (
          <Typography fontStyle="italic" fontSize="0.7rem" marginTop="1rem">
            *Nu puteți lăsa o recenzie pentru acest produs deoarece comanda nu a
            fost livrată.
          </Typography>
        )}
      </div>
    </>
  );
};

const UnavailableProduct = () => {
  return (
    <div style={{ margin: "auto" }}>
      <Typography variant="h6" fontWeight="bold" color={PSRed}>
        Produs indisponibil
      </Typography>
    </div>
  );
};

const ReviewPopup = ({
  productId,
  orderId,
  allProducts,
  openModal,
  handleCloseModal,
}) => {
  const classes = useStyles();
  const product = findProduct(productId, allProducts);
  const [score, setScore] = React.useState(5);
  const [message, setMessage] = React.useState("");
  const [response, setResponse] = React.useState("");

  const handleReview = async () => {
    const userJwt = localStorage.getItem("proseccoshop_token");

    if (!userJwt) {
      alert("Trebuie să fii autentificat pentru a putea lăsa o recenzie.");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userJwt}`,
      },
      body: JSON.stringify({
        productId: product.productId,
        orderId: orderId,
        review: {
          score: score,
          message: message,
        },
      }),
    };
    const reviewResponse = await fetch(
      `${serverUrl}/product-review`,
      requestOptions
    ).then((res) => res.json());
    const responseMessage = reviewResponse.message;

    setResponse(responseMessage);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => handleCloseModal}
      className={classes.modal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className={classes.modalContent}>
        <div
          style={{
            textAlign: "right",
            fontSize: "1.5rem",
            marginRight: "1%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "inline-block",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          >
            X
          </div>
        </div>
        {response === "" && (
          <>
            <h2 id="modal-title">Spune-ne părerea ta despre</h2>
            <h3>{product.name}</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem 1rem 0 1rem",
              }}
            >
              <ReactStars
                color2={PSGold}
                size={30}
                textAlign="center"
                margin="auto"
                value={score}
                onChange={(newScore) => setScore(newScore)}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>{score}/5</div>
            <TextareaAutosize
              style={{
                backgroundColor: PSDark,
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                color: PSGold,
                marginBottom: "1rem",
                width: "15rem",
                height: "8rem",
                padding: "1rem",
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div>
              <Button
                style={{
                  backgroundColor: PSGold,
                  color: PSDark,
                }}
                onClick={handleReview}
              >
                Trimite recenzia
              </Button>
            </div>
          </>
        )}
        {response === "Review added" && (
          <>
            <h2>Mulțumim!</h2>
            <div style={{ margin: "1.5rem" }}>
              Părerea ta contează! Recenzia ta a fost adăugată cu succes.
            </div>
            <Button
              style={{ backgroundColor: PSGold, color: PSDark }}
              onClick={handleCloseModal}
            >
              Închide
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

const orderStatusMap = {
  shipped: "expediată",
  confirmata: "confirmată",
};

const orderPaymentMap = {
  card: "card",
  cash: "numerar",
};
