import React, { createContext, useContext, useEffect, useState } from "react";
import { serverUrl } from "../consts/serverUrl";

const AuthContext = createContext({
  user: null,
  token: null,
  userCart: { guest: { line_items: {}, qty_items: 0 } },
  userCartLineItems: {},
  userCartQtyItems: 0,
  // @ts-ignore
  // @ts-ignore
  processLogin: (userData, userToken, rememberMe) => {},
  // @ts-ignore
  // @ts-ignore
  login: async (email, password, rememberMe) => {},
  logout: () => {},
  isAuthenticated: () => false,
  tokenAuth: () => {},
  retrieveUserCart: () => {},
  // @ts-ignore
  // @ts-ignore
  setUserCart: (newValue) => {},
  // @ts-ignore
  // @ts-ignore
  setUserCartLineItems: (newValue) => {},
  // @ts-ignore
  // @ts-ignore
  setUserCartQtyItems: (newValue) => {},
  getUserId: () => "string",
  // @ts-ignore
  setIsRedirectingToPayment: (value) => {},
});

export const AuthContextProvider = ({ children }) => {
  const [isRedirectingToPayment, setIsRedirectingToPayment] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [authDone, setAuthDone] = useState(false);
  /**
   * Get the user ID: email if authenticated, "guest" otherwise
   **/
  const getUserId = () => {
    // If the authentication is done:
    // - If the user is not null and the user has an email, return the user's email
    // - If the user is null or the user does not have an email, return "guest"
    // If the authentication is not done, return "waiting"
    if (!authDone) return "waiting";

    // @ts-ignore
    return user && user.email ? user.email : "guest";
  };

  const [userCart, setUserCart] = useState({
    guest: {
      line_items: {},
      qty_items: 0,
    },
  });

  const [userCartLineItems, setUserCartLineItems] = useState(
    userCart && userCart[getUserId()] && userCart[getUserId()].line_items
      ? userCart[getUserId()].line_items
      : {}
  );

  const [userCartQtyItems, setUserCartQtyItems] = useState(
    userCart && userCart[getUserId()] && userCart[getUserId()].qty_items
      ? parseInt(userCart[getUserId()].qty_items)
      : 0
  );

  const isAuthenticated = () => {
    // @ts-ignore
    return user !== null && token !== null && user.email !== null;
  };

  useEffect(() => {
    if (getUserId() !== "waiting") {
      setUserCart(retrieveUserCart());
      setUserCartLineItems(
        userCart[getUserId()] && userCart[getUserId()].line_items
          ? userCart[getUserId()].line_items
          : {}
      );
      setUserCartQtyItems(
        userCart[getUserId()] && userCart[getUserId()].qty_items
          ? parseInt(userCart[getUserId()].qty_items)
          : 0
      );
    }
  }, [user, authDone]);

  useEffect(() => {
    // Save the cart to local storage when the user is not logged in
    if (!user && !token && getUserId() === "guest") {
      saveCartToLocalstorage(getUserId(), userCart[getUserId()]);
    }
  }, [userCart, userCartLineItems, userCartQtyItems, user, token]);

  useEffect(() => {
    // Retrieve the cart from localstorage is the user is not logged in
    // and auth is done
    if (authDone && !user && !token && getUserId() === "guest") {
      const retrievedCart = retrieveUserCart();
      setUserCart(retrievedCart);
      setUserCartLineItems(
        retrievedCart[getUserId()] && retrievedCart[getUserId()].line_items
          ? retrievedCart[getUserId()].line_items
          : {}
      );
      setUserCartQtyItems(
        retrievedCart[getUserId()] && retrievedCart[getUserId()].qty_items
          ? parseInt(retrievedCart[getUserId()].qty_items)
          : 0
      );
    }
  }, [authDone]);

  // TODO: Now the cart is saved to the database on every change. Maybe remove this.
  // useEffect(() => {
  //   // Save the cart to the database before the page is unloaded
  //   const handleBeforeUnload = (event) => {
  //     // @ts-ignore
  //     if (!isRedirectingToPayment && token && user && user.email) {
  //       saveCartToDB(getUserId(), token, userCart[getUserId()]);
  //     }
  //     // Chrome requires returnValue to be set even if we don't show a popup
  //     event.returnValue = "";
  //   };

  //   // Save the cart when the visibility state changes (e.g., user switches tabs)
  //   const handleVisibilityChange = () => {
  //     if (
  //       document.visibilityState === "hidden" &&
  //       token &&
  //       user &&
  //       // @ts-ignore
  //       user.email
  //     ) {
  //       saveCartToDB(getUserId(), token, userCart[getUserId()]);
  //     }
  //   };

  //   const handlePageHide = (event) => {
  //     // @ts-ignore
  //     if (!isRedirectingToPayment && token && user && user.email) {
  //       saveCartToDB(getUserId(), token, userCart[getUserId()]);
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("pagehide", handlePageHide);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("pagehide", handlePageHide);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [user, token, userCart, isRedirectingToPayment]);

  // Save cart on every change
  useEffect(() => {
    if (
      token &&
      user &&
      // @ts-ignore
      user.email &&
      getUserId() !== "waiting" &&
      getUserId() !== "guest"
    ) {
      saveCartToDB(getUserId(), token, userCart[getUserId()]);
    }
  }, [userCart]);

  // FIXME: Uncomment after finding a solution: user is prompted to click leave page
  // when paying for the order -> very bad!
  // useEffect(() => {
  //   // Save the cart to the database when the user navigates away from the page
  //   const handlePopState = () => {
  //     const currentUrl = window.location.href;

  //     // TODO: uncomment localhost for testing
  //     const isInternalNavigation =
  //       // currentUrl.startsWith("http://localhost:3000") ||
  //       currentUrl.startsWith("https://proseccoshop.ro") ||
  //       currentUrl.startsWith("https://secure.mobilpay.ro/");

  //     // @ts-ignore
  //     if (!isInternalNavigation && user && user.email && token) {
  //       saveCartToDB(getUserId(), token, userCart[getUserId()]);
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [user, token, userCart]);

  // Try to authenticate user with stored token on first render
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("proseccoshop_user") || "{}");
    const token = localStorage.getItem("proseccoshop_token");
    // Option 1: If user has rememberMe true
    // Option 2: If user does not have rememberMe, but the session is still valid
    if (
      user.email &&
      token &&
      (user.rememberMe || Date.now() < user.sessionExpiration)
    ) {
      tokenLogin(user, token);
    } else {
      setAuthDone(true);
    }
  }, []);

  const login = async (email, password, rememberMe) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: password, rememberMe }),
    };

    return await fetch(`${serverUrl}/login`, requestOptions).then((res) =>
      res.json()
    );
  };

  const processLogin = (userInfo, authToken, rememberMe) => {
    // Construct the user object to store in local storage
    // Include the session expiration time (24 hours from now)
    // Include the rememberMe flag
    const localStorageUserInfo = {
      sessionExpiration: Date.now() + 1000 * 60 * 60 * 24,
      rememberMe: rememberMe,
      ...userInfo,
    };

    setUser(localStorageUserInfo);
    setToken(authToken);

    // Store the user object and token in local storage
    localStorage.setItem(
      "proseccoshop_user",
      JSON.stringify(localStorageUserInfo)
    );
    // Store the token in local storage
    localStorage.setItem("proseccoshop_token", authToken);

    saveCartToLocalstorage(userInfo.email, userInfo.cart);
    setUserCart(retrieveUserCart());
    setUserCartLineItems(
      userCart[getUserId()] && userCart[getUserId()].line_items
        ? userCart[getUserId()].line_items
        : {}
    );
    setUserCartQtyItems(
      userCart[getUserId()] && userCart[getUserId()].qty_items
        ? parseInt(userCart[getUserId()].qty_items)
        : 0
    );
    setAuthDone(true);
  };

  const logout = () => {
    // @ts-ignore
    if (token && user && user.email) {
      saveCartToDB(getUserId(), token, userCart[getUserId()]);
    }

    setUser(null);
    setToken(null);
    localStorage.removeItem("proseccoshop_user");
    localStorage.removeItem("proseccoshop_token");
    localStorage.removeItem("proseccoshop_user_cart");
  };

  /**
   * Authenticate user with stored token
   * @param {*} storedUser
   * @param {*} storedToken
   */
  // @ts-ignore
  // @ts-ignore
  const tokenLogin = async (storedUser, storedToken) => {
    try {
      const authResponse = await fetch(`${serverUrl}/login-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      }).then((res) => res.json());

      if (
        authResponse.message === "Login successful user" ||
        authResponse.message === "Login successful admin"
      ) {
        setUser(authResponse.userInfo);
        setToken(storedToken);
        saveCartToLocalstorage(
          authResponse.userInfo.email,
          authResponse.userInfo.cart
        );

        const cartObject = createLocalCartObject(
          authResponse.userInfo.email,
          authResponse.userInfo.cart
        );
        setUserCart(retrieveUserCart());
        setUserCartLineItems(
          cartObject[authResponse.userInfo.email]?.line_items || {}
        );
        setUserCartQtyItems(
          cartObject[authResponse.userInfo.email]?.qty_items || 0
        );

        localStorage.setItem(
          "proseccoshop_user",
          JSON.stringify({
            sessionExpiration: Date.now() + 1000 * 60 * 60 * 24,
            ...authResponse.userInfo,
          })
        );
      } else {
        logout();
      }
    } catch (error) {
      console.error("Error during token authentication:", error);
      logout();
    } finally {
      setAuthDone(true);
    }
  };

  /**
   * Helper function to create a local cart object that complies with the structure
   * of the user cart.
   * @param {*} email
   * @param {*} cart
   * @returns
   */
  const createLocalCartObject = (email, cart) => {
    const cartValue = cart ? cart : { line_items: {}, qty_items: 0 };
    return {
      [email]: cartValue,
    };
  };

  /**
   * Utility function to save the cart to the database. This function is called:
   * - When the user logs out
   * - Before the page is unloaded
   * @param {*} email
   * @param {*} token
   * @param {*} cart
   */
  const saveCartToDB = async (email, token, cart) => {
    // @ts-ignore
    const response = await fetch(`${serverUrl}/update-cart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email, cart }),
    }).then((res) => res.json());
  };

  const saveCartToLocalstorage = (email, cart) => {
    localStorage.setItem(
      "proseccoshop_user_cart",
      JSON.stringify(createLocalCartObject(email, cart))
    );
  };

  /**
   * Retrieve the user cart from local storage. If the cart is not found, return
   * an empty cart object.
   * @returns {Object}
   */
  const retrieveUserCart = () =>
    JSON.parse(
      localStorage.getItem("proseccoshop_user_cart") ||
        JSON.stringify({
          guest: {
            line_items: {},
            qty_items: 0,
          },
        })
    );

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        userCart,
        userCartLineItems,
        userCartQtyItems,
        login,
        processLogin,
        logout,
        // @ts-ignore
        isAuthenticated,
        retrieveUserCart,
        setUserCart,
        setUserCartLineItems,
        setUserCartQtyItems,
        getUserId,
        setIsRedirectingToPayment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
