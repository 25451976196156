import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { OneProduct } from "../OneProduct/OneProduct";
import useStyles from "./styles";
import { serverUrl } from "../../consts/serverUrl";
import { newsletterMessages } from "../../consts/loginMessages";
import { welcomePageTexts } from "../../consts/welcomePageTexts";
import { emailRegexTest } from "../../consts/functions";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { WelcomePageVideo } from "./WelcomePageVideo";

export const WelcomePage = (props) => {
  const {
    productList,
    setPage,
    setSelectedProduct,
    navbarHeight,
    setPreviousPage,
  } = props;
  window.onbeforeunload = (e) => {
    window.scrollTo(0, 0);
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [offersRetrieved, setOffersRetrieved] = useState(false);
  const [sortedOffersList, setSortedOffersList] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: 950px)` });

  const sortedProductList = productList.slice().sort((a, b) => {
    // Check if a.order and b.order are present, and fallback to 0 if they are not present
    const orderA = a.order ?? 0;
    const orderB = b.order ?? 0;

    return orderA - orderB; // Will work fine as both are strings now
  });

  const onClickHowTo = (chapter) => {
    navigate(`/descopera/${chapter}`);
  };
  const onClickShop = () => {
    setPage("Shop");
    navigate("/shop");
  };

  const handleMailNewsletter = async () => {
    const mailInput = document.getElementById("mailNewsletter");
    const mailNewsletter = mailInput ? mailInput.value : "";

    if (emailRegexTest(mailNewsletter)) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mail: mailNewsletter }),
      };

      try {
        const newsletterResponse = await fetch(
          `${serverUrl}/newsletter`,
          requestOptions
        )
          .then((res) => res.json())
          .then((res) => res.message);
        if (newsletterResponse) {
          if (newsletterResponse === newsletterMessages.success) {
            alert("V-ati abonat cu succes!");
          } else if (
            newsletterResponse === newsletterMessages.alreadyRegistered
          ) {
            alert("Sunteti deja abonat!");
          }
          // Reset email field
          if (mailInput) {
            mailInput.value = "";
          }
        } else {
          alert("Inregistrare esuata");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // Invalid email address
      alert("Please enter a valid email address");
    }
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [setPage]);

  useEffect(() => {
    const retrieveOffers = () => {
      let offersListLocal = [];
      if (productList && productList.length > 0) {
        productList.forEach((product) => {
          if (product.category === "Oferte") offersListLocal.push(product);
        });
      }
      if (offersListLocal && offersListLocal.length > 0) {
        const sortedOffersListLocal = offersListLocal.slice().sort((a, b) => {
          // Check if a.order and b.order are present, and fallback to 0 if they are not present
          const orderA = a.order ?? 0;
          const orderB = b.order ?? 0;

          return orderA - orderB; // Will work fine as both are strings now
        });
        if (sortedOffersListLocal.length > 0) {
          setSortedOffersList(sortedOffersListLocal);
        }
      }
    };

    retrieveOffers();

    if (productList && productList.length > 0) setOffersRetrieved(true);
  }, [productList, setOffersRetrieved]);

  return (
    <main className={classes.content}>
      <div style={{ height: navbarHeight * 0.8 }}></div>
      <WelcomePageVideo></WelcomePageVideo>
      {/* <div className={classes.background}>
        <div className={classes.backgroundTextHolder}></div>
        <div className={classes.divIntroTextContainer}>
          <div className={classes.divIntroBlank}></div>
          <div className={classes.divIntroBlank}></div>
        </div>
        <br></br>
        <div className={classes.backgroundTextHolder}></div>
      </div> */}
      <div className={classes.backgroundDarkHolderTitle}>
        <h3>PROSECCO, VIN ȘI SPUMANTE</h3>
      </div>
      {sortedProductList.length > 0 && (
        <div>
          <div className={classes.backgroundDarkHolderPreffered}>
            <Grid container className={classes.productsGridContainer}>
              {sortedProductList.map(
                (product) =>
                  product.preffered &&
                  product.preffered === "true" && (
                    <Grid item key={product.productId}>
                      <OneProduct
                        product={product}
                        setPage={setPage}
                        setSelectedProduct={setSelectedProduct}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </div>
          <div className={classes.backgroundDarkHolderTitle}>
            <br />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#0a0808",
                color: "#bcab79",
                width: "270px",
                border: "1px solid #bcab79",
              }}
              onClick={onClickShop}
            >
              Mergi la magazin pentru a vedea toate produsele
            </Button>
            <br />
          </div>
        </div>
      )}
      {sortedProductList.length === 0 && (
        <div className={classes.loaderBody}>
          <img
            src="../../favicon.ico"
            alt="logo"
            className={classes.loaderImage}
          ></img>
          <br />
          <br />
          <div>Pregătim cele mai bune produse pentru tine...</div>
        </div>
      )}
      <div className={classes.backgroundDarkHolderTitle}>
        <h3>OFERTE PROSECCO, VIN ȘI SPUMANTE</h3>
      </div>
      {offersRetrieved && sortedOffersList.length > 0 && (
        <div>
          <div className={classes.backgroundDarkHolderOffers}>
            <Grid container className={classes.productsGridContainer}>
              {sortedOffersList.map(
                (offer) =>
                  offer.category === "Oferte" && (
                    <Grid item key={offer.productId}>
                      <OneProduct
                        product={offer}
                        setPage={setPage}
                        setSelectedProduct={setSelectedProduct}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        </div>
      )}
      {sortedOffersList.length === 0 && !offersRetrieved && (
        <div className={classes.backgroundDarkHolderTitle}>
          <br />
          <br />
          <div style={{ color: "#bcab79" }}>
            Pregătim cele mai bune oferte pentru tine...
          </div>
          <br />
          <br />
        </div>
      )}
      {sortedOffersList.length === 0 && offersRetrieved && (
        <div className={classes.backgroundDarkHolderTitle}>
          <br />
          <br />
          <div style={{ color: "#bcab79" }}>
            Momentan nu există nicio ofertă activă.
          </div>
          <br />
          <br />
        </div>
      )}
      <div className={classes.backgroundLightHolderCocktail}>
        <div className={classes.backgroundLightHolderCocktailItems}>
          {!isMobile &&
            Object.values(welcomePageTexts.cocktailSection)
              .slice(1)
              .map((cocktail, index) => (
                <div key={index}>
                  <div className={classes.cocktailHolder} key={index}>
                    <div
                      onClick={() => onClickHowTo(cocktail.title)}
                      className={classes.cocktail}
                    >
                      {index % 2 == 0 ? (
                        <>
                          <img
                            src={cocktail.image}
                            alt={`Cocktail Image ${index + 1}`}
                            className={classes.cocktailImageLeft}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "auto",
                              padding: "1vw",
                            }}
                          >
                            <h2 className={classes.cocktailTitle}>
                              {cocktail.title}
                            </h2>
                            <p className={classes.cocktailDescription}>
                              {cocktail.preview}
                            </p>
                            <div style={{ textAlign: "center" }}>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#0a0808",
                                  color: "#bcab79",
                                  maxwidth: "15%",
                                }}
                              >
                                Descoperă
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "auto",
                              padding: "1vw",
                            }}
                          >
                            <h2 className={classes.cocktailTitle}>
                              {cocktail.title}
                            </h2>
                            <p className={classes.cocktailDescription}>
                              {cocktail.preview}
                            </p>
                            <div style={{ textAlign: "center" }}>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#0a0808",
                                  color: "#bcab79",
                                  maxwidth: "15%",
                                }}
                              >
                                Descoperă
                              </Button>
                            </div>
                          </div>
                          <img
                            src={cocktail.image}
                            alt={`Cocktail Image ${index + 1}`}
                            className={classes.cocktailImageRight}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <br />
                </div>
              ))}
          {isMobile && (
            <Grid
              container
              className={classes.productsGridContainer}
              xs={10}
              sm={8}
              md={6}
            >
              {Object.values(welcomePageTexts.cocktailSection)
                .slice(1)
                .map((cocktail, index) => (
                  <Grid item>
                    <div key={index}>
                      <div className={classes.cocktailHolder} key={index}>
                        <div
                          onClick={() => onClickHowTo(cocktail.title)}
                          className={classes.cocktail}
                        >
                          <>
                            <img
                              src={cocktail.image}
                              alt={`Cocktail Image ${index + 1}`}
                              className={classes.cocktailImage}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "auto",
                                padding: "1vw",
                              }}
                            >
                              <h2 className={classes.cocktailTitle}>
                                {cocktail.title}
                              </h2>
                              <p className={classes.cocktailDescription}>
                                {cocktail.preview}
                              </p>
                              <div style={{ textAlign: "center" }}>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#0a0808",
                                    color: "#bcab79",
                                    maxwidth: "15%",
                                  }}
                                >
                                  Descoperă
                                </Button>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      <br />
                    </div>
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
      </div>{" "}
      <div className={classes.backgroundDarkHolderNewsLetter}>
        <h2 style={{ fontWeight: "bold" }}>
          Vrei să fii mereu la curent cu toate noutățile și promoțiile?
        </h2>
        <br></br>
        <div style={{ fontWeight: "bold" }}>
          Abonează-te la newsletter și noi iți spunem când avem promoții la
          Prosecco, Vin, Spumant. Promitem să nu te plictisim cu e-mail-uri.
        </div>
        <br></br>
        <div>Lasă-ne adresa ta de mail pentru a te notifica:</div>
        <br></br>
        <input
          type="mail"
          id="mailNewsletter"
          placeholder="Adresa ta de mail"
          className={classes.inputMailNewsletter}
        ></input>
        <br></br>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#0a0808",
            color: "#bcab79",
          }}
          onClick={handleMailNewsletter}
        >
          Abonează-te
        </Button>
      </div>
    </main>
  );
};
