import { makeStyles } from "@mui/styles";
import shopBackground from "../../media/shop-background.avif";
import mainBackground from "../../media/main-background.avif";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  backgroundHolder: {
    height: "75vh",
    padding: "30%",
  },
  productsGridContainer: {
    width: "95vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
  },
  blackBackground: {
    backgroundColor: "black",
  },
  backgroundDarkHolder: {
    padding: "5%",
    height: "auto",
    backgroundColor: "#0a0808",
    display: "flex",
    flexDirection: "row",
    color: "#bcab79",
    alignItems: "center",
    textAlign: "center",
  },
  backgroundDarkHolderPreffered: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    color: "#bcab79",
    alignItems: "center",
    textAlign: "center",
    backgroundImage: `url(${shopBackground})`,
    width: "100vw",
    // backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  backgroundDarkHolderOffers: {
    height: "auto",
    minHeight: "25vh",
    display: "flex",
    flexDirection: "row",
    color: "#bcab79",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundImage: `url(${shopBackground})`,
    width: "100vw",
    // backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  backgroundDarkHolderTitle: {
    padding: "2% 0 0.5% 0",
    minHeight: "5vh",
    backgroundColor: "#0a0808",
    color: "#bcab79",
  },
  backgroundLightHolderTitle: {
    padding: "2% 0 0.5% 0",
    minHeight: "5vh",
    backgroundColor: "white",
    color: "#0a0808",
  },
  backgroundDarkHolderComingSoon: {
    height: "auto",
    backgroundColor: "#0a0808",
    color: "#bcab79",
  },
  backgroundDarkHolderDescription: {
    width: "100vw",
    height: "auto",
    backgroundColor: "#0a0808",
    display: "flex",
    flexDirection: "row",
    color: "#bcab79",
  },
  backgroundLightHolder: {
    margin: "2vh",
    minHeight: "75vh",
    backgroundColor: "F5F5F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundLightHolderPreffered: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    color: "#bcab79",
    alignItems: "center",
    textAlign: "center",
    backgroundImage: `url(${shopBackground})`,
    width: "100vw",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  backgroundLightHolderComingSoon: {
    margin: "2vh",
    backgroundColor: "F5F5F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundLightHolderNewsLetter: {
    width: "96vw",
    padding: "2vw",
    minHeight: "30vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundDarkHolderNewsLetter: {
    width: "96vw",
    padding: "2vw",
    minHeight: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0a0808",
    color: "#bcab79",
  },
  inputMailNewsletter: {
    border: "none",
    borderBottom: "1px solid black",
    fontSize: 18,
    textAlign: "center",
    width: "20vw",
  },
  backgroundLightHolderCocktail: {
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${shopBackground})`,
    maxWidth: "100vw",
    height: "auto",
    minHeight: "75vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundLightHolderCocktailItems: {
    maxWidth: "100vw",
    height: "auto",
    minHeight: "75vh",
    backgroundColor: "F5F5F5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundLightHolderShop: {
    backgroundColor: "white",
    height: "auto",
    margin: "auto",
  },
  backgroundTextHolder: {
    minHeight: "5vh",
  },
  darkTitleHolder: {
    textAlign: "center",
    fontSize: 36,
    marginTop: 0,
    maxHeight: "5vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "5px",
  },
  lightTitleHolder: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
    marginTop: 0,
    width: 200,
    backgroundColor: "yellow",
  },
  divIntroTextContainer: {
    display: "flex",
    flex: "auto",
    textAlign: "center",
    opacity: "90%",
    color: "black",
    fontSize: "35px",
  },
  divIntroText: {
    backgroundColor: "rgba(255,255,255,0.5)",
    fontWeight: "bold",
    width: "auto",
    fontSize: "20px",
  },
  divIntroBlank: {
    width: "40%",
  },
  btnViewProducts: {
    backgroundColor: "rgba(255,255,255,0.5)",
    color: "black",
    width: "auto",
    opacity: "1",
    borderWidth: "1px",
  },
  btnTextViewProducts: {
    color: "black",
    fontWeight: "bold",
  },
  background: {
    width: "100vw",
    height: "100vh",
    borderLeft: "50px",
    borderRight: "50px",
    borderColor: "#0a0808",
    backgroundImage: `url(${mainBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundColor: "#0a0808",
  },
  divImageColumn: {
    flex: 1,
    maxWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
  },
  divTextColumn: {
    flex: 1,
    padding: "5% 5% 5% 5%",
    color: "#bcab79",
    fontSize: 22,
    textAlign: "justify",
  },
  divTextColumnTitle: {
    textAlign: "center",
  },
  imageColumn: {
    height: "100%",
    objectFit: "cover",
  },
  imageColumnCocktail: {
    width: "100vw",
  },
  cocktailHolder: {
    margin: "1% auto",
    display: "flex",
    flexDirection: "row",
    width: "90%",
    maxWidth: "90%",
  },
  cocktail: {
    border: "1px solid #bcab79",
    maxWidth: "95vw",
    backgroundColor: "#0a0808",
    color: "#bcab79",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      backgroundColor: "#bcab79",
      color: "#0a0808",
    },
  },
  // cocktailImage: {
  //   width: "30vw",
  //   height: "auto",
  //   borderRadius: "10px 0 0 10px",
  //   aspectRatio: "auto",
  // },
  cocktailImage: {
    width: "100%",
    height: "auto",
    borderRadius: "10px 10px 0 0",
  },
  cocktailImageLeft: {
    width: "22vw",
    height: "auto",
    borderRadius: "10px 0 0 10px",
    aspectRatio: "2 / 3",
  },
  cocktailImageRight: {
    width: "22vw",
    height: "auto",
    borderRadius: "0 10px 10px 0",
    aspectRatio: "2 / 3",
  },

  cocktailTitle: {
    paddingTop: "2vw",
    marginBottom: "10px",
  },

  cocktailDescription: {
    padding: "2vw",
    fontSize: "1.3rem",
    textAlign: "justify",
  },

  cocktailRecipe: {
    fontWeight: "bold",
    marginBottom: "5px",
  },

  cocktailGlassType: {
    fontWeight: "bold",
  },

  sendMailButton: {
    backgroundColor: "#bcab79",
    border: "1px solid black",
    fontFamily: "Montserrat, sans-serif",
    "&:hover": {
      backgroundColor: "#0a0808",
      color: "#bcab79",
    },
  },
  cocktailSource: {
    fontSize: "12px",
    color: "#888",
  },
  gridContainer: {
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    color: "#bcab79",
    textAlign: "center",
  },
  loaderBody: {
    backgroundColor: "#0a0808",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "55vh",
    margin: "auto",
    color: "#bcab79",
  },
  loaderImage: {
    animation: "$spin 1s linear infinite",
    width: "15vw",
  },
  transitionDiv: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s, transform 0.5s",
  },
  transitionDivVisible: {
    opacity: 1,
    transform: "translateY(200)",
  },
  "@media (max-width: 1150px)": {
    backgroundDarkHolder: {
      flexDirection: "column",
    },
    backgroundDarkHolderPreffered: {
      flexDirection: "column",
    },
    divImageColumn: {
      width: "100%",
      maxWidth: "100%",
    },
    divTextColumn: {
      maxWidth: "100%",
      textAlign: "justify",
      fontSize: 13,
      maxHeight: "50vh",
      overflow: "auto",
      padding: "0% 7% 0% 7%",
      margin: "10% 0% 10% 0%",
    },
    imageColumn: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  "@media(max-width: 950px)": {
    cocktail: {
      flexDirection: "column",
    },
    cocktailDescription: {
      fontSize: "0.95rem",
    },
    cocktailHolder: {
      margin: "1% auto",
      display: "flex",
      flexDirection: "column",
      width: "90%",
      maxWidth: "90%",
    },
    backgroundLightHolderCocktailItems: {
      height: "auto",
      minHeight: "75vh",
      backgroundColor: "F5F5F5",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "@media (max-width: 768px)": {
    inputMailNewsletter: {
      width: "80vw",
    },
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));
