import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
  },
  cartBackground: {
    backgroundColor: "white",
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "rgb(0,0,0,0.9)",
  },
  loadingHolder: {
    height: "6.5vh",
    margin: "auto",
    width: "50%",
    textAlign: "center",
    justifyContent: "center",
  },
  productsGridContainer: {
    width: "95vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
  },
  centeredContent: {
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    width: "50%",
  },
}));
