import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  navHolder: {
    height: "7vh",
  },
  content: {
    textAlign: "center",
  },
  cartBackground: {
    backgroundColor: "white",
  },
  selectedImagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  selectedImageContainer: {
    position: "relative",
    width: "150px",
    height: "150px",
  },
  selectedImage: {
    border: "1px solid black",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "5px",
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    padding: "4px 8px",
    backgroundColor: "#ff0000",
    color: "#ffffff",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
  },
  imagesContainer: {
    border: "1px solid black",
    borderRadius: "10px",
    width: "100%",
    height: "30vh",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#D3D3D3",
  },
  buttonRemove: {
    backgroundColor: "#FF7F7F",
    borderRadius: "5px",
    boxShadow: "none",
  },
  buttonAddImage: {
    backgroundColor: "#90EE90",
    borderRadius: "5px",
    padding: "5px",
    border: "1px solid black",
  },
}));
