import React from "react";
import { PSGold } from "../../consts/styleConsts";

export const ReceiptSVG = () => {
  return (
    <svg viewBox="0 -0.5 25 25" fill="none" width="10vw">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.775 17.9344L6.847 18.8784C6.77321 18.9152 6.68572 18.9115 6.61528 18.8687C6.54484 18.8259 6.50131 18.7499 6.5 18.6674V5.33343C6.50165 5.25118 6.54531 5.17551 6.61569 5.13291C6.68607 5.09032 6.77337 5.08674 6.847 5.12343L8.775 6.06743C8.85208 6.10494 8.94319 6.09925 9.015 6.05243L10.567 5.03943C10.6479 4.98686 10.7521 4.98686 10.833 5.03943L12.367 6.03943C12.4479 6.09201 12.5521 6.09201 12.633 6.03943L14.167 5.03943C14.2479 4.98686 14.3521 4.98686 14.433 5.03943L15.984 6.05243C16.0558 6.09925 16.1469 6.10494 16.224 6.06743L18.153 5.12343C18.2266 5.08674 18.3139 5.09032 18.3843 5.13291C18.4547 5.17551 18.4984 5.25118 18.5 5.33343V18.6674C18.4984 18.7497 18.4547 18.8254 18.3843 18.868C18.3139 18.9106 18.2266 18.9141 18.153 18.8774L16.225 17.9334C16.1479 17.8959 16.0568 17.9016 15.985 17.9484L14.433 18.9614C14.3521 19.014 14.2479 19.014 14.167 18.9614L12.633 17.9614C12.5521 17.9089 12.4479 17.9089 12.367 17.9614L10.833 18.9614C10.7521 19.014 10.6479 19.014 10.567 18.9614L9.016 17.9484C8.94376 17.9016 8.85218 17.8963 8.775 17.9344Z"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
        <path
          d="M9.5 10.2505C9.08579 10.2505 8.75 10.5863 8.75 11.0005C8.75 11.4147 9.08579 11.7505 9.5 11.7505V10.2505ZM15.5 11.7505C15.9142 11.7505 16.25 11.4147 16.25 11.0005C16.25 10.5863 15.9142 10.2505 15.5 10.2505V11.7505ZM10.5 12.2505C10.0858 12.2505 9.75 12.5863 9.75 13.0005C9.75 13.4147 10.0858 13.7505 10.5 13.7505V12.2505ZM14.5 13.7505C14.9142 13.7505 15.25 13.4147 15.25 13.0005C15.25 12.5863 14.9142 12.2505 14.5 12.2505V13.7505ZM9.5 11.7505H15.5V10.2505H9.5V11.7505ZM10.5 13.7505H14.5V12.2505H10.5V13.7505Z"
          fill="#000000"
        ></path>{" "}
      </g>
    </svg>
  );
};
export const CheckMarkIcon = () => (
  <svg
    fill={PSGold}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 44.648 44.647"
    xmlSpace="preserve"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <g>
          <path d="M39.829,0H4.819C2.518,0,0.654,1.838,0.654,4.14v25.511c0,1.438,0.742,2.776,1.963,3.537l17.39,10.829 c1.342,0.836,3.04,0.841,4.387,0.013l17.619-10.845c1.23-0.758,1.98-2.101,1.98-3.547V4.14C43.994,1.839,42.13,0,39.829,0z M33.196,17.583l-11.691,11.69c-1.033,1.033-2.708,1.033-3.74,0l-5.794-5.794c-0.497-0.496-0.775-1.169-0.775-1.87 c0-0.702,0.278-1.374,0.775-1.87v-0.001c0.496-0.496,1.168-0.774,1.87-0.774c0.701,0,1.373,0.279,1.868,0.775l3.453,3.453 c0.126,0.125,0.296,0.196,0.472,0.196c0.178,0,0.348-0.07,0.474-0.196l9.348-9.348c0.497-0.496,1.17-0.775,1.871-0.774 c0.7,0,1.374,0.278,1.87,0.775l0,0C34.228,14.876,34.228,16.55,33.196,17.583z"></path>{" "}
        </g>
      </g>
    </g>
  </svg>
);

export const FailedMarkIcon = () => {
  return (
    <svg
      fill={PSGold}
      viewBox="0 -8 528 528"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM264 288L328 352 360 320 296 256 360 192 328 160 264 224 200 160 168 192 232 256 168 320 200 352 264 288Z"></path>
      </g>
    </svg>
  );
};

export const ShippingSVG = () => {
  return (
    <svg
      fill="#000000"
      width="10vw"
      version="1.1"
      id="Layer_1"
      viewBox="-49.11 -49.11 589.32 589.32"
      xmlSpace="preserve"
      transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
      stroke="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g transform="translate(0 -540.36)">
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <path d="M401.5,863.31c-12,0-23.4,4.7-32,13.2c-8.6,8.6-13.4,19.8-13.4,31.8s4.7,23.2,13.4,31.8c8.7,8.5,20,13.2,32,13.2 c24.6,0,44.6-20.2,44.6-45S426.1,863.31,401.5,863.31z M401.5,933.31c-13.8,0-25.4-11.4-25.4-25s11.6-25,25.4-25 c13.6,0,24.6,11.2,24.6,25S415.1,933.31,401.5,933.31z"></path>{" "}
              <path d="M413.1,713.41c-1.8-1.7-4.2-2.6-6.7-2.6h-51.3c-5.5,0-10,4.5-10,10v82c0,5.5,4.5,10,10,10h81.4c5.5,0,10-4.5,10-10v-54.9 c0-2.8-1.2-5.5-3.3-7.4L413.1,713.41z M426.5,792.81h-61.4v-62.1h37.4l24,21.6V792.81z"></path>{" "}
              <path d="M157.3,863.31c-12,0-23.4,4.7-32,13.2c-8.6,8.6-13.4,19.8-13.4,31.8s4.7,23.2,13.4,31.8c8.7,8.5,20,13.2,32,13.2 c24.6,0,44.6-20.2,44.6-45S181.9,863.31,157.3,863.31z M157.3,933.31c-13.8,0-25.4-11.4-25.4-25s11.6-25,25.4-25 c13.6,0,24.6,11.2,24.6,25S170.9,933.31,157.3,933.31z"></path>{" "}
              <path d="M90.6,875.61H70.5v-26.6c0-5.5-4.5-10-10-10s-10,4.5-10,10v36.6c0,5.5,4.5,10,10,10h30.1c5.5,0,10-4.5,10-10 S96.1,875.61,90.6,875.61z"></path>{" "}
              <path d="M141.3,821.11c0-5.5-4.5-10-10-10H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h121.3C136.8,831.11,141.3,826.71,141.3,821.11z"></path>{" "}
              <path d="M30.3,785.01l121.3,0.7c5.5,0,10-4.4,10.1-9.9c0.1-5.6-4.4-10.1-9.9-10.1l-121.3-0.7c-0.1,0-0.1,0-0.1,0 c-5.5,0-10,4.4-10,9.9C20.3,780.51,24.8,785.01,30.3,785.01z"></path>{" "}
              <path d="M50.7,739.61H172c5.5,0,10-4.5,10-10s-4.5-10-10-10H50.7c-5.5,0-10,4.5-10,10S45.2,739.61,50.7,739.61z"></path>{" "}
              <path d="M487.4,726.11L487.4,726.11l-71.6-59.3c-1.8-1.5-4-2.3-6.4-2.3h-84.2v-36c0-5.5-4.5-10-10-10H60.5c-5.5,0-10,4.5-10,10 v73.2c0,5.5,4.5,10,10,10s10-4.5,10-10v-63.2h234.8v237.1h-82c-5.5,0-10,4.5-10,10s4.5,10,10,10h122.1c5.5,0,10-4.5,10-10 s-4.5-10-10-10h-20.1v-191.1h80.6l65.2,54l-0.7,136.9H460c-5.5,0-10,4.5-10,10s4.5,10,10,10h20.3c5.5,0,10-4.4,10-9.9l0.8-151.6 C491,730.91,489.7,728.01,487.4,726.11z"></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export const CheckMarkPromotionCode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25"
      height="25"
      viewBox="0 0 48 48"
    >
      <path
        fill="#c8e6c9"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      ></path>
      <path
        fill="#4caf50"
        d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
      ></path>
    </svg>
  );
};

export const CreditCard = () => (
  <svg
    // width="800px"
    // height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Interface / Credit_Card_01">
      <path
        id="Vector"
        d="M3 11V15.8002C3 16.9203 3 17.4801 3.21799 17.9079C3.40973 18.2842 3.71547 18.5905 4.0918 18.7822C4.5192 19 5.07899 19 6.19691 19H17.8031C18.921 19 19.48 19 19.9074 18.7822C20.2837 18.5905 20.5905 18.2842 20.7822 17.9079C21 17.4805 21 16.9215 21 15.8036V11M3 11V9M3 11H21M3 9V8.2002C3 7.08009 3 6.51962 3.21799 6.0918C3.40973 5.71547 3.71547 5.40973 4.0918 5.21799C4.51962 5 5.08009 5 6.2002 5H17.8002C18.9203 5 19.4796 5 19.9074 5.21799C20.2837 5.40973 20.5905 5.71547 20.7822 6.0918C21 6.5192 21 7.07899 21 8.19691V9M3 9H21M7 15H11M21 11V9"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const Cash = () => (
  <svg
    fill="#000000"
    viewBox="-9.6 -9.6 115.20 115.20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0">
      <rect
        x="-9.6"
        y="-9.6"
        width="115.20"
        height="115.20"
        rx="0"
        fill="#ffffff"
        // strokewidth="0"
      ></rect>
    </g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <title></title>
      <path d="M90,30H84V12a5.9966,5.9966,0,0,0-6-6H18A18.02,18.02,0,0,0,0,24V72A18.02,18.02,0,0,0,18,90H90a5.9966,5.9966,0,0,0,6-6V36A5.9966,5.9966,0,0,0,90,30ZM18,18H72V30H18a6,6,0,0,1,0-12Zm0,60a6.0078,6.0078,0,0,1-6-6V40.8948A17.7918,17.7918,0,0,0,18,42H84V78Z"></path>
    </g>
  </svg>
);

export const Expiration = () => {
  return (
    <svg
      fill={PSGold}
      // height="800px"
      // width="800px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 86.285 86.285"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M9.83,40.531c0-10.4,4.728-20.016,12.969-26.381c1.577-1.218,1.868-3.484,0.65-5.062c-1.219-1.577-3.484-1.87-5.063-0.65
   C8.362,16.181,2.613,27.878,2.613,40.531c0,4.96,0.885,9.803,2.631,14.396c0.548,1.441,1.919,2.327,3.374,2.327
   c0.426,0,0.859-0.076,1.281-0.236c1.863-0.708,2.799-2.792,2.091-4.655C10.557,48.592,9.83,44.611,9.83,40.531z"
        />
        <path
          d="M43.143,0c-4.357,0-8.644,0.689-12.743,2.046c-1.893,0.626-2.918,2.668-2.292,4.56c0.626,1.892,2.668,2.916,4.56,2.292
   c3.367-1.115,6.892-1.68,10.476-1.68c18.368,0,33.312,14.944,33.312,33.313c0,18.37-14.944,33.316-33.312,33.316
   c-4.274,0-8.415-0.816-12.288-2.357l4.341-0.578c1.976-0.264,3.364-2.078,3.101-4.054c-0.264-1.977-2.091-3.36-4.054-3.101
   l-13.864,1.848c-1.976,0.264-3.364,2.078-3.101,4.054c0.024,0.179,0.07,0.348,0.118,0.517c0.015,0.537,0.139,1.077,0.402,1.578
   l6.624,12.601c0.646,1.229,1.899,1.931,3.197,1.931c0.566,0,1.141-0.134,1.676-0.415c1.765-0.928,2.443-3.109,1.516-4.873
   L29.64,78.77c4.29,1.516,8.836,2.295,13.503,2.295c22.348,0,40.529-18.183,40.529-40.533C83.672,18.182,65.49,0,43.143,0z"
        />
        <path
          d="M41.477,36.553c0-3.083-2.577-5.129-5.811-5.129c-2.325,0-4.195,0.581-5.61,1.288c-0.379,0.202-0.556,0.506-0.556,0.936
   c0,0.556,0.404,0.984,0.96,0.984c0.202,0,0.43-0.101,0.48-0.126c1.137-0.505,2.603-0.909,4.321-0.909
   c2.931,0,3.992,1.339,3.992,3.133c0,4.119-10.107,5.938-10.107,11.27c0,0.784,0.556,1.314,1.339,1.314h9.754
   c0.606,0,1.087-0.455,1.087-1.062c0-0.606-0.48-1.086-1.087-1.086h-8.794C32.81,44.209,41.477,41.758,41.477,36.553z"
        />
        <path
          d="M54.31,49.439c0.632,0,1.162-0.505,1.162-1.137v-3.437h1.921c0.581,0,1.035-0.43,1.035-1.011
   c0-0.581-0.454-1.036-1.035-1.036h-1.921v-9.805c0-0.809-0.581-1.465-1.466-1.465h-0.505c-0.506,0-0.985,0.252-1.264,0.607
   l-7.935,10.133C44.126,42.517,44,42.87,44,43.198v0.228c0,0.809,0.657,1.44,1.466,1.44h7.706v3.437
   C53.172,48.935,53.678,49.439,54.31,49.439z M46.451,42.819l6.721-8.667v8.667H46.451z"
        />
        <path
          d="M28.158,24.259c3.995-3.487,9.119-5.407,14.429-5.407c12.105,0,21.953,9.85,21.953,21.956c0,0.996,0.809,1.805,1.805,1.805
   c0.996,0,1.805-0.809,1.805-1.805c0-14.096-11.468-25.564-25.563-25.564c-6.183,0-12.149,2.236-16.802,6.297
   c-0.751,0.655-0.828,1.795-0.173,2.546C26.268,24.836,27.408,24.914,28.158,24.259z"
        />
      </g>
    </svg>
  );
};
