import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useStyles from "./styles";
import { findItemByTitle } from "../../consts/helpers";

export const HowTo = (props) => {
  const { setPreviousPage } = props;
  const { chapter } = useParams();
  const { pathname } = useLocation();
  const classes = useStyles();
  const displayedItem = findItemByTitle(chapter);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    setPreviousPage("");
  }, []);

  return (
    <main className={classes.root}>
      <div className={classes.navHolder}></div>
      <div className={classes.chapterTitleHolder}>
        <h1>{displayedItem.title}</h1>
      </div>
      <br />
      <img
        className={classes.chapterImg}
        src={`/${displayedItem.image}`}
        alt={`descopera-${displayedItem.title}`}
      />
      <br />
      <br />
      <div className={classes.chapterAboutHolder}>
        <div>{displayedItem.text}</div>
      </div>
    </main>
  );
};
