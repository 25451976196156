import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import ReactStars from "react-stars";
import { PSGold } from "../../consts/styleConsts";
import { calculateProductRating } from "../../consts/functions";
import { ProductReviewMessages } from "./ProductReviewMessages";

export const ProductReviews = ({ selectedProduct }) => {
  const classes = useStyles();
  const [rating, setRating] = useState("5");

  const reviewsCount = selectedProduct.reviews?.length ?? 0;

  useEffect(() => {
    const productReviews = selectedProduct.reviews ?? [];
    setRating(calculateProductRating(productReviews));
  }, [selectedProduct]);

  return (
    <>
      <div className={classes.productDescriptionHolder}>
        <h2>Recenzii</h2>
        <ReactStars
          value={rating}
          edit={false}
          color2={PSGold}
          size={30}
          textAlign="center"
        ></ReactStars>

        {reviewsCount === 0 ? (
          <div style={{ fontSize: "15px" }}>0 recenzii de afișat</div>
        ) : (
          <>
            <br />
            <div>Rating: {rating}/5</div>
            <div>({reviewsCount} recenzii)</div>
          </>
        )}
        <br />
        <ProductReviewMessages reviews={selectedProduct.reviews ?? []} />
        <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
          * Este foarte simplu să lași o recenzie! Nu trebuie decât să îți faci
          un cont și să plasezi o comandă. După ce ai degustat și savurat
          produsele noastre, tot ce trebuie să faci este să intri în contul tău,
          la secțiunea „Istoric comenzi”. Acolo vei găsi toate produsele pe care
          le-ai achiziționat și vei avea posibilitatea să lași o recenzie pentru
          fiecare produs în parte. Apreciem părerea ta și ne dorim să
          îmbunătățim mereu serviciile noastre!
        </div>
      </div>
    </>
  );
};
