import React, { useEffect } from "react";
import LandingPage from "./components/LandingPage/LandingPage";
import { AuthContextProvider, useAuth } from "./contexts/AuthProvider";
import "./App.css";
import {
  AdminAuthContextProvider,
  useAdminAuth,
} from "./contexts/AdminAuthProvider";

const App = () => {
  const { tokenAuth } = useAuth();
  const { tokenAdminLogin } = useAdminAuth();

  useEffect(() => {
    tokenAuth();
  });
  useEffect(() => {
    tokenAdminLogin();
  });
  return (
    <AuthContextProvider>
      <AdminAuthContextProvider>
        <LandingPage></LandingPage>
      </AdminAuthContextProvider>
    </AuthContextProvider>
  );
};

export default App;
