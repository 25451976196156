import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { CheckMarkIcon } from "../svgs/UtilSVGs";
import useStyles from "./styles";
import { SpinnerBody } from "../Spinner/Spinner";
import { serverUrl } from "../../consts/serverUrl";

export const MailConfirmation = ({ navbarHeight }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(true);
  const [loading, setLoading] = useState(true);

  const { token, email } = useParams();
  const location = useLocation();

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);

    // Verify token with backend
    const verifyToken = async () => {
      if (!token || !email) {
        // navigate("/");
        return;
      }

      try {
        const response = await fetch(
          `${serverUrl}/mail-verified?token=${token}&email=${email}`
        );

        if (!response.ok) {
          // navigate("/"); // Redirect to home if verification failed
        } else {
          setIsVerified(true);
        }
      } catch (error) {
        // navigate("/"); // Redirect to home if there's an error
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [navigate, location.search]);

  const onClickGoToLogin = () => {
    navigate("/auth");
  };

  if (loading) {
    return <SpinnerBody message={""} />;
  }

  return isVerified ? (
    <main className={classes.centeredContent}>
      <div className={classes.navHolder}></div>
      <div className={classes.pageTextColor}>
        <div>
          <div style={{ height: navbarHeight }}></div>
          <h1>Cont creat cu succes</h1>
          <br />
          <br />
          <h2>
            Felicitări pentru finalizarea creării contului tău! Adresa ta de
            email a fost verificată.
          </h2>
          <br />
          <br />
          <div className={classes.iconHolder}>
            <CheckMarkIcon />
          </div>
          <br />
          <br />
          <Button
            style={{ color: "#0a0808", backgroundColor: "#bcab79" }}
            onClick={onClickGoToLogin}
          >
            Mergi la login
          </Button>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </main>
  ) : (
    <div>
      <div className={classes.navHolder}></div>
      <div>Unauthorized access</div>
    </div>
  );
};
