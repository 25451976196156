import React from "react";
import useStyles from "./styles";

/**
 * A component that displays a spinner on a centered container of 100vh height.
 * @param {*} param0
 * @returns
 */
export const SpinnerBody = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.loaderBody}>
      <Spinner />
      <br />
      <br />
      <div>{message}</div>
    </div>
  );
};

export const Spinner = () => {
  const classes = useStyles();
  return <img src="/favicon.ico" className={classes.loaderImage}></img>;
};
