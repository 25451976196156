import React from "react";
import { useMediaQuery } from "react-responsive";

// TODO: Add close button
const InfoBanner = ({ title, subtitle }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "#bcab79",
          color: "#0a0808",
        }}
      >
        <div
          style={{ position: "absolute", top: "0.2rem", right: "0.5rem" }}
        ></div>
        <div
          style={
            isMobile
              ? { fontSize: "0.75rem", margin: "0.65rem", fontWeight: "bold" }
              : { fontSize: "0.9rem", margin: "0.65rem", fontWeight: "bold" }
          }
        >
          {title}
        </div>
        {subtitle && (
          <div
            style={
              isMobile
                ? { fontSize: "0.6rem", marginBottom: "0.65rem" }
                : { fontSize: "0.75rem", marginBottom: "0.65rem" }
            }
          >
            {subtitle}
          </div>
        )}
      </div>
    </>
  );
};

export default InfoBanner;
