import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    display: "contents",
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  backgroundDarkHolder: {
    width: "100vw",
    textAlign: "center",
    height: "auto",
    backgroundColor: "#0a0808",
    display: "flex",
    flexDirection: "row",
    color: "#bcab79",
  },
  imgLogo: {
    width: "15vw",
  },
  columnHolder: {
    width: "30vw",
    paddingTop: "2%",
  },
  brandHolder: { fontSize: 11 },
  "@media (max-width: 768px)": {
    backgroundDarkHolder: {
      height: "auto",
      backgroundColor: "#0a0808",
      display: "flex",
      flexDirection: "column",
      color: "#bcab79",
    },
    imgLogo: {
      width: "50vw",
    },
    columnHolder: {
      width: "100vw",
      // borderColor: "#bcab79",
      // borderWidth: "0.05px 0 0 0",
      // borderStyle: "solid",
    },
  },
}));
