import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  navHolder: {
    height: "7vh",
  },
  cartBackground: {
    backgroundColor: "white",
  },
  restHolder: { minHeight: "60vh" },
  centeredContent: {
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    width: "50%",
  },
}));
