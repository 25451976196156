import { makeStyles } from "@mui/styles";
import shopBackground from "../../media/shop-background.avif";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "rgb(0,0,0,0.9)",
  },
  brHolder: {
    minHeight: "5vh",
  },
  shopBackground: {
    backgroundImage: `url(${shopBackground})`,
    // backgroundSize: "cover",
    width: "auto",
    // backgroundPosition: "0px 0px",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
  imgShopBackground: {
    width: "100vw",
  },
  gridHolder: {
    height: "5vh",
  },
  selectMenuContainerMobile: {
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    color: "#bcab79",
    textAlign: "center",
  },
  selectMenuContainerDesktop: {
    // height: "10vh",
    width: "95vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    cursor: "pointer",
  },
  productsGridContainer: {
    width: "95vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
  },
  menuPaper: {
    backgroundColor: "transparent", // Set the background color to white
  },
  toggleButton: { color: "#bcab79" },
  backgroundDarkHolderProducts: {
    height: "auto",
    minHeight: "75vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundDarkHolderTitle: {
    margin: "0",
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0.9);",
    color: "#bcab79",
    // height: "35vh",
  },
  loaderBody: {
    backgroundColor: "#0a0808",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: 0,
  },
  loaderImage: {
    animation: "$spin 1s linear infinite",
    width: "15vw",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  "@media (max-width: 768px)": {
    navHolder: {
      minHeight: "4vh",
    },
    backgroundDarkHolderTitle: {
      margin: "0",
      textAlign: "center",
      padding: "5% 0 5% 0",
      color: "#bcab79",
    },
    backgroundDarkHolderProducts: {
      flexDirection: "column",
    },
    loaderImage: { width: "40vw" },
  },
}));
