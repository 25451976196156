import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";

const theme = createTheme();

const drawerWidth = 0;

export default makeStyles((theme) => ({
  appBarColored: {
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "transparent",
  },
  appBarTransparent: {
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "transparent",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    flexGrow: 1,
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
    fontSize: "2rem",
  },
  image: {
    maxWidth: "3.5vw",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    width: "100%",
  },
  customIconButton: {
    color: "#bcab79", // Specify the custom RGB color value
  },
  "@media (max-width: 768px)": {
    image: { maxWidth: "10vw" },
  },
}));
