import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SpinnerBody } from "../../Spinner/Spinner";
import useStyles from "./styles";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { PSDark, PSGold } from "../../../consts/styleConsts";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { serverUrl } from "../../../consts/serverUrl";
import CustomErrorMessage from "../../Error/CustomErrorMessage";

export const ResetPassword = ({ navbarHeight }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const location = useLocation();

  const { token, email } = useParams();
  // const [queryParams] = useSearchParams();
  // const token = queryParams.get("token");
  // const email = queryParams.get("email");

  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
    // Verify token with backend
    if (!token || !email) {
      navigate("/");
      return;
    }

    setIsVerified(true);

    setLoading(false);
  }, [navigate, location.search]);

  const onClickGoToLogin = () => {
    navigate("/auth");
  };

  const handleResetPassword = async () => {
    if (!password) {
      setError("No password");
      return;
    }

    try {
      const response = await fetch(`${serverUrl}/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, email, password }),
      }).then((res) => res.json());

      if (response.message !== "Success") {
        alert("Actualizarea parolei a eșuat. Contactează-ne pentru ajutor.");
        return;
      }

      setSuccess(true);
    } catch (error) {
      alert(`Eroare la resetarea parolei: ${error}`);
    }
  };

  if (loading) {
    return <SpinnerBody message={""} />;
  }

  return isVerified ? (
    <main className={classes.centeredContent}>
      <div className={classes.contentBackgroundHolder}>
        <div className={classes.innerContentBackground}>
          <div className={classes.navHolder} />
          <div className={classes.pageTextColor}>
            <div>
              <div style={{ height: navbarHeight }}></div>
              <h1>Resetează parola</h1>
              <br />
              <br />
              <div>Introdu noua ta parolă</div>
              <OutlinedInput
                style={{
                  border: `1px solid ${PSGold}`,
                  borderRadius: "10px",
                  marginBottom: "10px",
                  color: PSGold,
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  width: "13rem",
                  height: "2.5rem",
                }}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment style={{ height: "2.5rem" }} position="end">
                    <IconButton
                      aria-label="toggle password"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      // @ts-ignore
                      edge="end"
                      style={{ marginRight: "1%" }}
                    >
                      {showPassword ? (
                        <VisibilityOff style={{ color: PSGold }} />
                      ) : (
                        <Visibility style={{ color: PSGold }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="password"
              ></OutlinedInput>
              {error === "No password" && (
                <CustomErrorMessage message="Introdu noua parolă" />
              )}
              <br />
              <br />
              <Button
                style={{ backgroundColor: PSGold, color: PSDark }}
                onClick={handleResetPassword}
                disabled={success}
              >
                Resetează
              </Button>
              {success && (
                <div>
                  <br />
                  <br />
                  <div>Parola a fost resetată cu succes.</div>
                  <br />
                  <br />
                  <Button
                    style={{ backgroundColor: PSGold, color: PSDark }}
                    onClick={onClickGoToLogin}
                  >
                    Mergi la login
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <div>
      <div className={classes.navHolder}></div>
      <div>Unauthorized access</div>
    </div>
  );
};
