import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    display: "contents",
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  qtyInput: {
    width: "4vw",
    "@media (max-width: 768px)": {
      width: "6vw",
    },
  },
  inputMailNewsletter: {
    border: "none",
    borderBottom: "1px solid black",
    fontSize: 18,
    textAlign: "center",
    width: "20vw",
  },
  cartBackground: {
    backgroundColor: "white",
  },
  continueButton: {
    backgroundColor: "green",
  },
  cartMessage: {
    fontWeight: "bold",
  },
  cartColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "5px",
    "@media (max-width: 768px)": {
      gap: "1vw",
      left: "10vw",
      width: "96vw ",
      flexDirection: "column",
    },
  },
  cartColumn: {
    display: "flex",
    flex: "0 1 calc(100%)",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 768px)": {
      flex: "0 1 calc(20% - 16px)",
      flexDirection: "column",
    },
    "@media (max-width: 480px)": {
      flex: "1 1 100%",
    },
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  "@media (max-width: 768px)": {
    navHolder: {
      height: "7vh",
    },
    inputMailNewsletter: {
      width: "80vw",
    },
  },
}));
