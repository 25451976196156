import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useStyles from "./styles";
import { serverUrl } from "../../consts/serverUrl";
import { uploadUrl } from "../../consts/uploadsUrl";
import { DeleteForeverOutlined } from "@mui/icons-material";

export const OneProductEdit = (props) => {
  const { product, setPage, setSelectedProduct } = props;

  const classes = useStyles();

  const onClickProduct = (product, setPage, setSelectedProduct) => {
    setSelectedProduct(product);
    setPage("EditProduct");
  };

  const onClickDeleteProduct = async (productId) => {
    const adminJwt = localStorage.getItem("proseccoshop_admin_token") || "";
    if (!adminJwt) {
      alert("Token de admin inexistent!");
      return;
    }

    const reqBody = { productId: productId };

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminJwt}`,
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    };

    try {
      const response = await fetch(
        `${serverUrl}/delete-product`,
        requestOptions
      );
      if (response.status === 200)
        alert(`Produsul "${product.productName}" a fost sters cu succes!`);
      else {
        alert(`Eroare la stergerea produsului "${product.productName}"`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let imageUrl = "";
  if (product.images && product.images.length > 0)
    imageUrl = `${uploadUrl}${product.images[0]}`;
  return (
    <div>
      <Card
        style={{ backgroundColor: "#0a0808" }}
        className={classes.productCard}
      >
        <Carousel showThumbs={false}>
          {product.images.map((imageUrl, index) => (
            <CardMedia
              className={classes.media}
              title={product.name}
              onClick={() =>
                onClickProduct(product, setPage, setSelectedProduct)
              }
            >
              <img
                src={`${uploadUrl}${imageUrl}`}
                style={{ width: "100%" }}
              ></img>
            </CardMedia>
          ))}
        </Carousel>
        <CardContent
          style={{
            fontFamily: "Montserrat, sans-serif",
            backgroundColor: "#0a0808",
            color: "#bcab79",
            textAlign: "center",
          }}
          onClick={() => onClickProduct(product, setPage, setSelectedProduct)}
        >
          <div className={classes.cardContent}>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif" }}
              variant="h6"
              gutterBottom
              height="8vh"
            >
              {product.name}
            </Typography>
          </div>
          <Typography
            style={{ fontFamily: "Montserrat, sans-serif", color: "#bcab79" }}
            variant="body2"
            color="textSecondary"
          >
            {product.category}
          </Typography>
          <br />
          <br />
          <Typography
            style={{ fontFamily: "Montserrat, sans-serif", color: "#bcab79" }}
            variant="h6"
            color="textSecondary"
          >
            ORDINE AFISARE: {product.order}
          </Typography>
          <br />
          <Typography
            style={{ fontFamily: "Montserrat, sans-serif", color: "#bcab79" }}
            variant="h6"
            color="textSecondary"
          >
            PRIMA PAGINA: {product.preffered}
          </Typography>
        </CardContent>
        <CardActions
          style={{ backgroundColor: "#0a0808" }}
          disableSpacing
          className={classes.cardActions}
        >
          <Typography
            style={{ fontFamily: "Montserrat, sans-serif", color: "#bcab79" }}
          >
            {product.price}RON
          </Typography>
        </CardActions>
        <br />
        <CardActions
          style={{
            backgroundColor: "#0a0808",
            justifyContent: "center",
            width: "100%",
          }}
          disableSpacing
          // className={classes.cardActions}
        >
          <Typography style={{ textAlign: "center", justifyContent: "center" }}>
            <DeleteForeverOutlined
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#bcab79",
                cursor: "pointer",
                fontSize: "3rem",
              }}
              onClick={() => onClickDeleteProduct(product.productId)}
            ></DeleteForeverOutlined>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
};
