// texts and images used inside welcomePage

export const welcomePageTexts = {
  proseccoHistory: {
    title: `Prosecco și istoria lui`,
    text: `Prosecco si istoria lui: Nu toată lumea știe acest lucru, dar prosecco
          are o istorie foarte bogată, fiind apreciat chiar și de vechii romani.
          Acesta a fost „readus la viață” abia în secolul al XVI-lea, când
          localnicii din zona Trieste au vrut să recreeze vinul din Antichitate.
          Deși multă lume crede că prosecco este un vin nou apărut, adevărul
          este că acesta există de peste 2000 de ani. Istoricii au consemnat că
          prosecco era consumat încă de pe vremea romanilor. Acest vin a fost
          foarte apreciat de către Plinius cel Bătrân, dar și de către Livia,
          soția împăratului Augustus. Se spunea că vinul are proprietăți
          vindecătoare, fiind folosit pentru diverse suferințe ale stomacului,
          dar era administrat și celor care sufereau accidente. Pe atunci,
          strugurele din soiul Glera era cultivat pe terenurile dintr-o zonă
          care era numită pe atunci Puccino. Pe teritoriul acesteia s-a
          dezvoltat și satul Prosecco, așa cum îl cunoaștem astăzi. Ce este
          prosecco? Prosecco este un vin alb spumant produs în Italia, într-o
          zonă care se întinde pe 9 provincii, în regiunile Veneto și Friuli
          Venezia Giulia. Acesta a fost denumit după satul Prosecco, care se
          află în provincia italiană Trieste. Este realizat din soiul de
          struguri Glera, care înainte era cunoscut chiar sub numele de
          Prosecco. Această schimbare de nume a apărut în 2010, când Ministerul
          de Agricultură italian a decis ca „Prosecco” să reprezinte numai
          denumirea geografică, nu și strugurii. Astfel, producția de vin
          spumant prosecco a fost limitată strict la această regiune geografică,
          ceea ce înseamnă faptul că nu orice vin produs din strugurii Glera se
          poate numi astfel. Prosecco poartă acest nume doar dacă e produs în
          regiunile Friuli, Veneția, Giulia și Veneto. Prosecco este obținut
          prin Metoda Charmat (cunoscută și sub numele de „Metoda Tank” la
          italieni ca „Metodo Italiano”), un proces prin care fermentația
          secundară (care produce carbonatarea) are loc în rezervoare mari din
          oțel inoxidabil. Tipuri de prosecco Vinul prosecco este produs în trei
          varietăți: Spumante (complet acidulat), Frizzante (slab acidulat) și
          Calmo sau Tranquillo (neacidulat). Spumante este un vin mai scump,
          care se găsește pe piața în sticla tradițională de vin, în timp de
          Frizzante se vinde atât la sticlă, cât și la doză. Vinul Tranquillo se
          vinde aproape exclusiv în Italia, fiind exportat foarte rar. Cel mai
          bun prosecco provine din regiunile Conegliano-Valdobbiadene și îl poți
          recunoaște după eticheta pe care poate scrie „Prosecco di
          Conegliano-Valdobbiadene”, ”Prosecco di Valdobbiadene”, „Prosecco di
          Conegliano” sau „Prosecco DOCG”.`,
    image: `description-image.avif`,
  },
  cocktailSection: {
    generalImage: `cocktail-background.avif`,
    prosecco0: {
      title: `Prosecco și istoria lui`,
      preview: `Prosecco si istoria lui: Nu toată lumea știe acest lucru, dar prosecco
      are o istorie foarte bogată, fiind apreciat chiar și de vechii romani.
      Acesta a fost „readus la viață” abia în secolul al XVI-lea, când
      localnicii din zona Trieste au vrut să recreeze vinul din Antichitate.
      Deși multă lume crede că prosecco este un vin nou apărut, adevărul
      este că ...`,
      text: `Prosecco si istoria lui: Nu toată lumea știe acest lucru, dar prosecco
      are o istorie foarte bogată, fiind apreciat chiar și de vechii romani.
      Acesta a fost „readus la viață” abia în secolul al XVI-lea, când
      localnicii din zona Trieste au vrut să recreeze vinul din Antichitate.
      Deși multă lume crede că prosecco este un vin nou apărut, adevărul
      este că acesta există de peste 2000 de ani. Istoricii au consemnat că
      prosecco era consumat încă de pe vremea romanilor. Acest vin a fost
      foarte apreciat de către Plinius cel Bătrân, dar și de către Livia,
      soția împăratului Augustus. Se spunea că vinul are proprietăți
      vindecătoare, fiind folosit pentru diverse suferințe ale stomacului,
      dar era administrat și celor care sufereau accidente. Pe atunci,
      strugurele din soiul Glera era cultivat pe terenurile dintr-o zonă
      care era numită pe atunci Puccino. Pe teritoriul acesteia s-a
      dezvoltat și satul Prosecco, așa cum îl cunoaștem astăzi. Ce este
      prosecco? Prosecco este un vin alb spumant produs în Italia, într-o
      zonă care se întinde pe 9 provincii, în regiunile Veneto și Friuli
      Venezia Giulia. Acesta a fost denumit după satul Prosecco, care se
      află în provincia italiană Trieste. Este realizat din soiul de
      struguri Glera, care înainte era cunoscut chiar sub numele de
      Prosecco. Această schimbare de nume a apărut în 2010, când Ministerul
      de Agricultură italian a decis ca „Prosecco” să reprezinte numai
      denumirea geografică, nu și strugurii. Astfel, producția de vin
      spumant prosecco a fost limitată strict la această regiune geografică,
      ceea ce înseamnă faptul că nu orice vin produs din strugurii Glera se
      poate numi astfel. Prosecco poartă acest nume doar dacă e produs în
      regiunile Friuli, Veneția, Giulia și Veneto. Prosecco este obținut
      prin Metoda Charmat (cunoscută și sub numele de „Metoda Tank” la
      italieni ca „Metodo Italiano”), un proces prin care fermentația
      secundară (care produce carbonatarea) are loc în rezervoare mari din
      oțel inoxidabil. Tipuri de prosecco Vinul prosecco este produs în trei
      varietăți: Spumante (complet acidulat), Frizzante (slab acidulat) și
      Calmo sau Tranquillo (neacidulat). Spumante este un vin mai scump,
      care se găsește pe piața în sticla tradițională de vin, în timp de
      Frizzante se vinde atât la sticlă, cât și la doză. Vinul Tranquillo se
      vinde aproape exclusiv în Italia, fiind exportat foarte rar. Cel mai
      bun prosecco provine din regiunile Conegliano-Valdobbiadene și îl poți
      recunoaște după eticheta pe care poate scrie „Prosecco di
      Conegliano-Valdobbiadene”, ”Prosecco di Valdobbiadene”, „Prosecco di
      Conegliano” sau „Prosecco DOCG”.`,
      image: `description-image.avif`,
    },
    cocktail1: {
      title: `Hugo`,
      preview: `Iubit de doamne și domnișoare, și tot mai apreciat de domni,
      Hugo este un cocktail cu gust dulce – acrișor, care te poartă cu
      gândul la vacanță. Acesta a fost inventat în 2005 de către
      barmanul Roland Gruber, care a vrut să creeze o alternativă la
      Aperol Spritz. Într-un moment de inspirație, acesta a amestecat ...`,
      text: `Iubit de doamne și domnișoare, și tot mai apreciat de domni,
    Hugo este un cocktail cu gust dulce – acrișor, care te poartă cu
    gândul la vacanță. Acesta a fost inventat în 2005 de către
    barmanul Roland Gruber, care a vrut să creeze o alternativă la
    Aperol Spritz. Într-un moment de inspirație, acesta a amestecat
    prosecco, sirop de lămâie, frunze de mentă, apă minerală
    carbogazoasă, cuburi de gheață și o felie de lămâie. Iar
    rezultatul i-a cucerit pe consumatori! Cinci ani mai târziu
    cocktailul a devenit tot mai popular în Germania, rețeta fiind
    doar ușor adaptată, iar astăzi băutura este cunoscută și iubită
    în toată lumea.`,
      image: `hugo-new.avif`,
    },
    cocktail2: {
      title: `Mimosa`,
      preview: `Mimosa este un cocktail popular și ușor de pregătit care este
      adesea servit la micul dejun sau brunch. Acest cocktail clasic
      combină Prosecco cu suc proaspăt de portocale. Prosecco aduce o
      notă răcoritoare și bule fine, în timp ce sucul de portocale
      adaugă o notă vibrantă și citrică. Proportiile recomandate sunt
      ...`,
      text: `Mimosa este un cocktail popular și ușor de pregătit care este
      adesea servit la micul dejun sau brunch. Acest cocktail clasic
      combină Prosecco cu suc proaspăt de portocale. Prosecco aduce o
      notă răcoritoare și bule fine, în timp ce sucul de portocale
      adaugă o notă vibrantă și citrică. Proportiile recomandate sunt
      de obicei un pahar de Prosecco la un pahar de suc de portocale,
      însă acestea pot fi ajustate în funcție de preferințe. Mimosa
      este adesea servit într-un pahar de șampanie și este potrivit
      pentru a vă răsfăța diminețile sau pentru a vă bucura de un
      brunch relaxant în compania prietenilor sau familiei.`,
      image: `mimosa-new.avif`,
    },
    cocktail3: {
      title: `Spritz`,
      preview: `Spritz, cunoscut și sub numele de Aperol Spritz, este un
      cocktail răcoritor și revigorant de origine italiană. Acesta se
      prepară amestecând Prosecco cu Aperol, un lichior de culoare
      portocalie și gust ușor amarui, și cu apă minerală. Cantitățile
      precise și proporțiile pot varia, dar în general, se folosesc
      ...`,
      text: `Spritz, cunoscut și sub numele de Aperol Spritz, este un
      cocktail răcoritor și revigorant de origine italiană. Acesta se
      prepară amestecând Prosecco cu Aperol, un lichior de culoare
      portocalie și gust ușor amarui, și cu apă minerală. Cantitățile
      precise și proporțiile pot varia, dar în general, se folosesc
      cantități egale de Prosecco și Aperol, iar apoi se completează
      cu apă minerală și gheață. Spritz este adesea servit cu o felie
      de portocală sau un mănunchi de măsline ca decor. Gustul său
      ușor amar și răcoritor îl face o alegere perfectă pentru a vă
      relaxa într-o seară caldă de vară sau pentru a savura o băutură
      socială cu prietenii.`,
      image: `aperol-new.avif`,
    },
  },
};
