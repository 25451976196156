import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
  },
  loaderBody: {
    backgroundColor: "#0a0808",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: 0,
  },
  loaderImage: {
    animation: "$spin 1s linear infinite",
    width: "15vw",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  "@media (max-width: 768px)": {
    loaderImage: { width: "40vw" },
  },
}));
