import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  root: {
    maxWidth: "100%",
    minWidth: "35vh",
    borderRadius: "10px",
  },
  // productCard: {
  //   width: "24vw",
  //   margin: "2vw auto",
  //   backgroundColor: "#0a0808",
  //   border: "1px solid #bcab79",
  // },
  productCard: {
    width: "22vw",
    margin: "2vw auto",
    backgroundColor: "#0a0808",
    border: "1px solid #bcab79",
  },
  media: {
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    aspectRatio: "3 / 4",
    objectFit: "contain",
    backgroundImage: "black",
    margin: "auto",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    height: "15vh",
  },
  "@media(max-width: 900px)": {
    productCard: {
      width: "40vw",
    },
  },
  "@media(max-width: 768px)": {
    productCard: {
      width: "50vw",
    },
  },
  "@media(max-width: 600px)": {
    productCard: {
      width: "60vw",
    },
  },
  "@media(max-width: 500px)": {
    productCard: {
      width: "85vw",
    },
  },
}));
