import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { ManageProducts } from "../ManageProducts/ManageProducts";
import { AddProduct } from "../AddProduct/AddProduct";
import { EditProduct } from "../EditProduct/EditProduct";
import { ViewProducts } from "../ViewProducts/ViewProducts";
import { ManageSearch } from "@mui/icons-material";
import { ViewOrders } from "../ViewOrders/ViewOrders";
import { ManagePromotions } from "./ManagePromotions/ManagePromotions";
import { AddPromotion } from "./ManagePromotions/AddPromotion";
import { useAdminAuth } from "../../contexts/AdminAuthProvider";

export const AdminPage = (props) => {
  const { adminLogin, getAdminId, isAuthenticatedAdmin } = useAdminAuth();
  const { navbarHeight, setPreviousPage } = props;
  const [page, setPage] = useState("AdminPage");
  const [selectedProduct, setSelectedProduct] = useState({});
  const classes = useStyles();
  const handleManageProducts = () => {
    setPage("ManageProducts");
  };
  const handleManagePromos = () => {
    setPage("ManagePromos");
  };
  const handleViewOrders = () => {
    setPage("ViewOrders");
  };
  useEffect(() => {
    setPreviousPage("");
  }, []);

  // @ts-ignore
  return getAdminId() === "waiting" ? (
    <main className={classes.pageContent}>
      <div style={{ height: navbarHeight }}></div>
      <div style={{ height: navbarHeight }}></div>
      <h1 style={{ color: "#bcab79" }}>Pagina de admin se încarcă...</h1>
      <div style={{ height: navbarHeight }}></div>
    </main>
  ) : !isAuthenticatedAdmin() ? (
    <main className={classes.pageContent}>
      <div style={{ height: navbarHeight }}></div>
      <div className={classes.contentBackgroundHolder}>
        <div className={classes.spaceBeforeContent}></div>
        <h1>Autentificare</h1>
        <div>Adresa de mail</div>
        <input type="mail" id="mail"></input>
        <br></br>
        <div>Parola</div>
        <input type="password" id="password"></input>
        <br></br>
        <br></br>
        <input type="checkbox" value="lsRememberMe" id="rememberMe" />{" "}
        <label htmlFor="rememberMe">Ramai conectat</label>
        <br></br>
        <br></br>
        <button
          onClick={() =>
            adminLogin(
              // @ts-ignore
              document.getElementById("mail").value || "",
              // @ts-ignore
              document.getElementById("password").value || "",
              true
            )
          }
        >
          Autentificare
        </button>
        <br />
        <br />
        <br />
        <br />
      </div>
    </main>
  ) : page === "AdminPage" ? (
    <main>
      <div className={classes.cartBackground}>
        <div style={{ height: navbarHeight }}></div>
        <div className={classes.centeredContent}>
          <h2>Admin Page</h2>
          <button onClick={handleManageProducts}>
            <ManageSearch></ManageSearch>
            <br></br>Manager Produse
          </button>
          <br />
          <br />
          <button onClick={handleViewOrders}>
            <ManageSearch></ManageSearch>
            <br></br>Manager Comenzi
          </button>
          <br />
          <br />
          <button onClick={handleManagePromos}>
            <ManageSearch></ManageSearch>
            <br></br>Manager Promotii
          </button>
        </div>
        <div className={classes.restHolder}></div>
      </div>
    </main>
  ) : page === "ManageProducts" ? (
    <ManageProducts
      setPage={setPage}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></ManageProducts>
  ) : page === "AddProduct" ? (
    <AddProduct
      setPage={setPage}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></AddProduct>
  ) : page === "EditProduct" ? (
    <EditProduct
      setPage={setPage}
      selectedProduct={selectedProduct}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></EditProduct>
  ) : page === "ViewProducts" ? (
    <ViewProducts
      setPage={setPage}
      setSelectedProduct={setSelectedProduct}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></ViewProducts>
  ) : page === "ViewOrders" ? (
    <ViewOrders
      setPage={setPage}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></ViewOrders>
  ) : page === "ManagePromos" ? (
    <ManagePromotions
      setPage={setPage}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></ManagePromotions>
  ) : page === "AddPromo" ? (
    <AddPromotion
      setPage={setPage}
      navbarHeight={navbarHeight}
      setPreviousPage={setPreviousPage}
    ></AddPromotion>
  ) : (
    <div></div>
  );
};
