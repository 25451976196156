import React from "react";
import { Divider } from "@mui/material";
import { PSGold } from "../../consts/styleConsts";

export const ProductReviewMessages = ({ reviews }) => {
  return (
    <div>
      {reviews.map((review, index) => (
        <div key={index}>
          <Divider color={PSGold} />
          <div>
            <div>
              <h3>{review.name}</h3>
              <div>{review.review.score} / 5</div>
              <h5 style={{ fontStyle: "italic" }}>
                {review.review.message !== ""
                  ? `"${review.review.message}"`
                  : "Utilizatorul nu a lăsat un mesaj"}
              </h5>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
