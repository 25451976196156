import { makeStyles } from "@mui/styles";
import authBackground from "../../../media/shop-background.avif";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    fontFamily: "Montserrat, sans-serif",
  },
  root: {
    flexGrow: 1,
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "rgb(0,0,0,0.9)",
  },
  pageTextColor: {
    color: "#bcab79",
    width: "95vw",
    margin: "auto",
  },
  centeredContent: {
    textAlign: "center",
  },
  iconHolder: {
    width: "10vw",
    margin: "auto",
    "@media(max-width:768px)": {
      width: "40vw",
    },
  },
  loaderBody: {
    backgroundColor: "#0a0808",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "95vh",
    margin: 0,
    color: "#bcab79",
  },
  loaderImage: {
    animation: "$spin 1s linear infinite",
    width: "15vw",
  },
  contentBackgroundHolder: {
    backgroundImage: `url(${authBackground})`,
    height: "92.8vh",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    color: "#bcab79",
  },
  innerContentBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "100%",
  },
}));
