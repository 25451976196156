import React, { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { AddShoppingCart } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useStyles from "./styles";
import {
  calculateProductRating,
  onClickAddToCart,
} from "../../consts/functions";
import { useNavigate } from "react-router-dom";
import { uploadUrl } from "../../consts/uploadsUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useAuth } from "../../contexts/AuthProvider";
import { PSDark, PSGold } from "../../consts/styleConsts";
import ReactStars from "react-stars";

export const OneProduct = (props) => {
  const { product, setSelectedProduct } = props;
  const {
    user,
    userCart,
    setUserCart,
    userCartLineItems,
    setUserCartLineItems,
    userCartQtyItems,
    setUserCartQtyItems,
    getUserId,
  } = useAuth();
  const navigate = useNavigate();

  const classes = useStyles();
  const [productSku, setProductSku] = useState(100);
  const [selectedProductCartQty, setSelectedProductCartQty] = useState(0);
  const [selectedProductRemainingSku, setSelectedProductRemainingSku] =
    useState(productSku);

  const updateProductQty = (product) => {
    // Calculate the SKU value
    let sku = product.sku !== "" ? parseInt(product.sku) : 0;
    // Update SKU state
    setProductSku(sku);

    // Check if the product is in the cart
    if (
      userCart[getUserId()]?.line_items &&
      userCart[getUserId()].line_items[product.productId]
    ) {
      // Get the cart quantity for the selected product
      let selectedProductCartQty =
        userCart[getUserId()].line_items[product.productId];
      // Update the cart quantity state
      setSelectedProductCartQty(selectedProductCartQty);
      // Calculate and update remaining SKU
      setSelectedProductRemainingSku(sku - selectedProductCartQty);
    } else {
      setSelectedProductRemainingSku(sku);
    }
  };

  useEffect(() => {
    updateProductQty(product);
  }, [selectedProductRemainingSku, productSku, userCart, product]);

  const onClickProduct = (product, setSelectedProduct) => {
    setSelectedProduct(product);
    navigate(`/produs/${encodeURIComponent(product.name)}`);
  };
  let imageUrl = "";
  if (product.images && product.images.length > 0)
    imageUrl = `${uploadUrl}${product.images[0]}`;

  const rating = calculateProductRating(product.reviews || []);
  return (
    <div>
      <Card
        style={{ backgroundColor: "#0a0808", borderRadius: "10px" }}
        className={classes.productCard}
      >
        <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
          {product.images.map((imageUrl, index) => (
            <CardMedia
              key={index}
              className={classes.media}
              title={product.name}
              style={{ cursor: "pointer" }}
              onClick={() => onClickProduct(product, setSelectedProduct)}
            >
              <LazyLoadImage
                key={index}
                style={{
                  width: "100%",
                  aspectRatio: "3 / 4",
                }}
                src={`${uploadUrl}${imageUrl}`}
                loading="lazy"
                effect="blur"
                placeholderSrc={`${uploadUrl}${imageUrl}`}
              ></LazyLoadImage>
              {(productSku === 0 ||
                (selectedProductRemainingSku <= 0 && productSku > 0)) && (
                <div
                  style={{
                    backgroundColor: "black",
                    width: "100%",
                    height: "100%",
                    opacity: "0.8",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <div
                    style={{
                      top: "50%",
                      position: "inherit",
                      color: "#bcab79",
                    }}
                  >
                    {productSku === 0 && `Stoc epuizat`}
                    {selectedProductRemainingSku <= 0 &&
                      productSku > 0 &&
                      `Ați adăugat în coș toate unitățile din stoc`}
                  </div>
                </div>
              )}
            </CardMedia>
          ))}
        </Carousel>
        <CardContent
          style={{
            fontFamily: "Montserrat, sans-serif",
            backgroundColor: "#0a0808",
            color: "#bcab79",
            textAlign: "center",
            margin: "auto",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => onClickProduct(product, setSelectedProduct)}
        >
          <div className={classes.cardContent}>
            <Typography
              className={classes.productName}
              variant="h6"
              gutterBottom
            >
              {product.name}
            </Typography>
            <ReactStars
              value={rating}
              edit={false}
              color2={PSGold}
              size={30}
              textAlign="center"
            ></ReactStars>
          </div>

          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#bcab79",
            }}
            variant="body2"
            color="textSecondary"
          >
            {product.category}
          </Typography>
        </CardContent>
        <CardActions
          style={{
            backgroundColor: PSDark,
            height: "8%",
            borderTop: "0.1px solid #bcab79",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          disableSpacing
          className={classes.cardActions}
        >
          {!product.discount ? (
            <div>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#bcab79",
                }}
              >
                {parseFloat(product.price).toFixed(2)} RON
              </Typography>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#990000",
                  textDecoration: "line-through",
                }}
              >
                {parseFloat(product.price).toFixed(2)} RON
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#bcab79",
                }}
              >
                {(
                  (parseFloat(product.price) *
                    (100.0 - parseFloat(product.discount))) /
                  100.0
                ).toFixed(2)}{" "}
                RON
              </Typography>
            </div>
          )}
          {selectedProductRemainingSku < 1 && (
            <IconButton
              style={{ color: "#bcab79" }}
              aria-label="Add to Cart"
              disabled={true}
              onClick={() => {
                onClickAddToCart(
                  product,
                  user.email,
                  userCartLineItems,
                  userCartQtyItems,
                  setUserCartLineItems,
                  setUserCartQtyItems,
                  userCart,
                  setUserCart
                );
              }}
            >
              <AddShoppingCart style={{ color: "grey" }}></AddShoppingCart>
            </IconButton>
          )}
          {selectedProductRemainingSku >= 1 && (
            <IconButton
              style={{ color: "#bcab79" }}
              aria-label="Add to Cart"
              onClick={() => {
                onClickAddToCart(
                  product,
                  getUserId(),
                  userCartLineItems,
                  userCartQtyItems,
                  setUserCartLineItems,
                  setUserCartQtyItems,
                  userCart,
                  setUserCart
                );
              }}
            >
              <AddShoppingCart style={{ color: "#bcab79" }}></AddShoppingCart>
            </IconButton>
          )}
        </CardActions>
      </Card>
    </div>
  );
};
