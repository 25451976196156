import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Button, CardMedia, Typography } from "@mui/material";
import { AddShoppingCart, LocalShipping } from "@mui/icons-material";
import { serverUrl } from "../../consts/serverUrl";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import useStyles from "./styles";
import { onClickAddToCart } from "../../consts/functions";
import { uploadUrl } from "../../consts/uploadsUrl";
import { shippingThreshold } from "../../consts/shipping";
import { scrollToTop } from "../../consts/helpers";
import { ProductReviews } from "./ProductReviews";
import { useAuth } from "../../contexts/AuthProvider";

export const ProductPage = (props) => {
  const {
    userCart,
    userCartLineItems,
    userCartQtyItems,
    setUserCart,
    setUserCartLineItems,
    setUserCartQtyItems,
  } = useAuth();
  const { selectedProduct, setSelectedProduct, navbarHeight, setPreviousPage } =
    props;
  const { getUserId } = useAuth();
  const { productName } = useParams();
  const [qtyToAdd, setQtyToAdd] = useState(1);
  const [productsRetrieved, setProductsRetrieved] = useState(false);
  const [selectedProductSku, setSelectedProductSku] = useState(100);
  const [, setSelectedProductCartQty] = useState(0);
  const [selectedProductRemainingSku, setSelectedProductRemainingSku] =
    useState(selectedProductSku);
  const classes = useStyles();
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  if (!loadedOnce) scrollToTop();

  useEffect(() => {
    setPreviousPage("ProductPage");
  }, []);

  useEffect(() => {
    setLoadedOnce(true);
  }, [selectedProduct]);

  let imageUrl = "";
  if (selectedProduct.images && selectedProduct.images.length > 0)
    imageUrl = `${uploadUrl}${selectedProduct.images[0]}`;
  else imageUrl = "";

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleQtyChange = (e) => {
    const newQty = parseInt(e.target.value);
    if (newQty < 1) e.target.value = 1;
    if (newQty && newQty > 0) setQtyToAdd(newQty);
    else setQtyToAdd(1);
  };

  const updateProductQty = (product) => {
    // Calculate the SKU value
    let sku = product.sku !== "" ? parseInt(product.sku) : 0;

    // Update SKU state
    setSelectedProductSku(sku);

    // Check if the product is in the cart
    if (
      userCart[getUserId()]?.line_items &&
      userCart[getUserId()].line_items[selectedProduct.productId]
    ) {
      // Get the cart quantity for the selected product
      let selectedProductCartQty =
        userCart[getUserId()].line_items[selectedProduct.productId];

      // Update the cart quantity state
      setSelectedProductCartQty(selectedProductCartQty);

      // Calculate and update remaining SKU
      setSelectedProductRemainingSku(sku - selectedProductCartQty);
    } else {
      setSelectedProductCartQty(0);
      setSelectedProductRemainingSku(sku);
    }
  };

  const fetchProduct = async () => {
    if (selectedProduct.name !== productName) {
      try {
        const response = await fetch(
          `${serverUrl}/product/${encodeURIComponent(
            productName ? productName : ""
          )}`
        ).then((res) => res.json());
        if (response) {
          setSelectedProduct(response);
        } else {
          setSelectedProduct(null);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
        setSelectedProduct(null);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProduct();
  }, [productName]);

  useEffect(() => {
    if (selectedProduct) {
      updateProductQty(selectedProduct);
      setProductsRetrieved(true);
    }
  }, [userCart, selectedProduct]);

  return !productsRetrieved ? (
    <div className={classes.loaderBody}>
      <img src="../../favicon.ico" className={classes.loaderImage}></img>
    </div>
  ) : (
    <main className={classes.root}>
      <div style={{ height: navbarHeight }}></div>
      <div className={classes.productInfoHolder}>
        <div className={classes.productCarouselHolder}>
          {selectedProduct.images && (
            <Carousel showThumbs={false} infiniteLoop={true} showStatus={false}>
              {selectedProduct.images.map((imageUrl, index) => (
                <CardMedia
                  className={`${classes.carouselHeight} custom-media`}
                  title={selectedProduct.name}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                    onClick={() => openModal(index)} // Open the modal with the clicked image
                  >
                    <img
                      alt="ProductImage"
                      src={`${uploadUrl}${imageUrl}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                    ></img>
                  </div>
                  {selectedProductSku === 0 && (
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100%",
                        height: "100%",
                        opacity: "0.8",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      onClick={() => openModal(index)}
                    >
                      <div style={{ top: "50%", position: "inherit" }}>
                        Produs indisponibil momentan
                      </div>
                    </div>
                  )}
                </CardMedia>
              ))}
            </Carousel>
          )}
        </div>

        <Modal
          className={classes.modal}
          open={isModalOpen}
          onClose={closeModal}
        >
          <div className={classes.modalContent}>
            <div style={{ margin: "auto" }}>
              <div
                style={{
                  textAlign: "right",
                  fontSize: "1.5rem",
                  marginRight: "1%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={closeModal}
                >
                  X
                </div>
              </div>

              <br />

              <Carousel
                showThumbs={true}
                thumbWidth={70}
                infiniteLoop={true}
                showStatus={false}
                selectedItem={selectedImageIndex}
                useKeyboardArrows={true}
              >
                {selectedProduct.images.map((imageUrl, index) => (
                  <CardMedia
                    className={`${classes.carouselHeightModal} custom-media`}
                    title={selectedProduct.name}
                    key={index}
                  >
                    <img
                      src={`${uploadUrl}${imageUrl}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "85vh",
                        objectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      alt={`img${index}`}
                    ></img>
                  </CardMedia>
                ))}
              </Carousel>
            </div>
          </div>
        </Modal>
        <div className={classes.productAboutHolder}>
          <h1>{selectedProduct.name}</h1>
          <div>COD: {selectedProduct.productId} </div>
          {!selectedProduct.discount ? (
            <h2>
              {parseFloat(selectedProduct.price).toFixed(2)} RON TVA inclus
            </h2>
          ) : (
            <div>
              <br />
              <br />
              <span
                style={{
                  fontWeight: "bolder",
                }}
              >
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "#990000",
                    fontSize: "1.3em",
                  }}
                >
                  {parseFloat(selectedProduct.price).toFixed(2)} RON
                </span>
                <br />
                <span
                  style={{
                    fontSize: "1.5em",
                  }}
                >
                  {" "}
                  {(
                    (parseFloat(selectedProduct.price) *
                      (100.0 - parseFloat(selectedProduct.discount))) /
                    100.0
                  ).toFixed(2)}{" "}
                  RON TVA inclus
                </span>
              </span>
              <span></span>
              <br />
              <br />
            </div>
          )}
          {selectedProductSku === 0 && (
            <div style={{ color: "#990000" }}>Stoc epuizat</div>
          )}
          {selectedProductSku > 0 && selectedProductSku <= 20 && (
            <div
              style={{ color: "#990000" }}
            >{`Ultimele ${selectedProductSku} unități!`}</div>
          )}
          {selectedProductSku > 20 && (
            <h3
              style={{ color: "#2e7d32" }}
            >{`În stoc (${selectedProductSku} unități)`}</h3>
          )}
          <br />
          <div>
            <div style={{ fontWeight: "bold" }}>Cantitate</div>
            <div className={classes.quantityInput}>
              <input
                id="qtyField"
                className={classes.quantityField}
                type="number"
                min="1"
                defaultValue="1"
                onChange={handleQtyChange}
              />
            </div>
          </div>
          <br />
          <div className={classes.quantityInput}>
            {selectedProductRemainingSku < qtyToAdd && (
              <Button
                style={{
                  backgroundColor: "grey",
                  color: "#0a0808",
                }}
                disabled={selectedProductRemainingSku < qtyToAdd}
                onClick={() =>
                  onClickAddToCart(
                    selectedProduct,
                    getUserId(),
                    userCartLineItems,
                    userCartQtyItems,
                    setUserCartLineItems,
                    setUserCartQtyItems,
                    userCart,
                    setUserCart,
                    qtyToAdd
                  )
                }
              >
                <AddShoppingCart></AddShoppingCart>
                <span>Adaugă în coș</span>
              </Button>
            )}
            {selectedProductRemainingSku >= qtyToAdd && (
              <Button
                style={{
                  backgroundColor: "#bcab79",
                  color: "#0a0808",
                }}
                disabled={selectedProductRemainingSku < qtyToAdd}
                onClick={() => {
                  onClickAddToCart(
                    selectedProduct,
                    getUserId(),
                    userCartLineItems,
                    userCartQtyItems,
                    setUserCartLineItems,
                    setUserCartQtyItems,
                    userCart,
                    setUserCart,
                    qtyToAdd
                  );
                  scrollToTop();
                }}
              >
                <AddShoppingCart></AddShoppingCart>
                <span>Adaugă în coș</span>
              </Button>
            )}
            <br />
            <br />
          </div>
          <br />
          <div>
            <LocalShipping></LocalShipping>
            <Typography>
              Asigurăm livrarea gratuită pentru comenzile de cel puțin{" "}
              {shippingThreshold} RON
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.smallBrHolder}></div>
      <div className={classes.productDescriptionHolder}>
        <h2>Caracteristici</h2>
        <div>Volum: {selectedProduct.volume}L</div>
        <br />
        <div>Procent alcool: {selectedProduct.alcohol}%</div>
        <br />
        <div>Tara de origine: {selectedProduct.country}</div>
        <br />
        <div>Categorie: {selectedProduct.category}</div>
        <br />
        <h2>Notele somelierului</h2>
        <div>Temperatura de servire: {selectedProduct.temperature}</div>
        <br />
        <div>Asocieri: {selectedProduct.associations}</div>
        <br />
        <h2>Descriere</h2>
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html: selectedProduct.description.replace(/\n/g, "<br>"),
          }}
        ></div>
      </div>
      <div className={classes.smallBrHolder}></div>
      <ProductReviews selectedProduct={selectedProduct}></ProductReviews>
      <br />
    </main>
  );
};
