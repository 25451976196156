import { makeStyles } from "@mui/styles";
import authBackground from "../../media/auth-background.avif";

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    minHeight: "83.7vh",
  },
  root: {
    flexGrow: 1,
  },
  cartBackground: {
    backgroundColor: "white",
  },
  navHolder: {
    height: "7vh",
    backgroundColor: "#0a0808",
  },
  spaceBeforeContent: {
    minHeight: "25vh",
  },
  contentBackgroundHolder: {
    backgroundImage: `url(${authBackground})`,
    height: "92.8vh",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    color: "#bcab79",
  },
  pageContent: {
    flexGrow: 1,
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#0a0808",
  },
  centeredContent: {
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    width: "50%",
  },
  restHolder: { minHeight: "60vh" },

  "@media (max-width: 768px)": {
    pageContent: {
      flexGrow: 1,
      textAlign: "center",
      fontFamily: "Montserrat, sans-serif",
      minHeight: "48vh",
    },
  },
}));
