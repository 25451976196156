import React, { useEffect } from "react";
import useStyles from "./styles";
import { AddBox, ModeEdit } from "@mui/icons-material";

export const ManageProducts = (props) => {
  const { setPage, navbarHeight, setPreviousPage } = props;

  const classes = useStyles();
  const addProductHandler = () => {
    setPage("AddProduct");
  };
  const viewProductsHandler = () => {
    setPage("ViewProducts");
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  return (
    <div className={classes.cartBackground}>
      <br></br>
      <br></br>
      <br></br>
      <div style={{ height: navbarHeight }}></div>

      <div className={classes.centeredContent}>
        <h2>Product Manager Page</h2>
        <br></br>

        <button onClick={addProductHandler}>
          <AddBox></AddBox>
          <br></br>Adauga un produs nou
        </button>
        <br></br>
        <br></br>
        <button onClick={viewProductsHandler}>
          <ModeEdit></ModeEdit>
          <br></br>Editeaza produse existente
        </button>
        <div className={classes.restHolder}></div>
      </div>
    </div>
  );
};
