import React, { useState } from "react";
import useStyles from "./styles";
import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Link,
  OutlinedInput,
} from "@mui/material";
import { serverUrl } from "../../../consts/serverUrl";
import { signUpMessages } from "../../../consts/loginMessages";
import { emailRegexTest } from "../../../consts/functions";
import { PSDark, PSGold } from "../../../consts/styleConsts";
import { Expiration } from "../../svgs/UtilSVGs";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignUp = (props) => {
  const { navbarHeight } = props;
  const classes = useStyles();

  // signup state: MailVerification | SignUp
  const [singupState, setSingupState] = useState("SignUp");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    /** @type {{ preventDefault: () => void; }} */ event
  ) => {
    event.preventDefault();
  };

  const handleSignUp = async () => {
    let email, plainPassword, firstName, lastName;
    const mailElement = document.getElementById("mail");
    const passwordElement = document.getElementById("password");
    const firstNameElement = document.getElementById("firstName");
    const lastNameElement = document.getElementById("lastName");

    if (mailElement instanceof HTMLInputElement) {
      email = mailElement.value;
      if (!email || !emailRegexTest(email))
        mailElement.classList.add(`${classes.wrongField}`);
    }

    if (passwordElement instanceof HTMLInputElement) {
      plainPassword = passwordElement.value;
      if (!plainPassword) {
        passwordElement.classList.add(`${classes.wrongField}`);
      }
    }

    if (firstNameElement instanceof HTMLInputElement) {
      firstName = firstNameElement.value;
      if (!firstName) firstNameElement.classList.add(`${classes.wrongField}`);
    }

    if (lastNameElement instanceof HTMLInputElement)
      lastName = lastNameElement.value;

    if (email && plainPassword && firstName && emailRegexTest(email)) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: plainPassword,
          permissions: "User",
        }),
      };

      let signUpResponse = await fetch(`${serverUrl}/register`, requestOptions)
        .then((res) => res.json())
        .then((res) => res.message);

      if (signUpResponse === signUpMessages.successSignUp)
        setSingupState("MailVerification");
      else if (signUpResponse === signUpMessages.userExists) {
        alert("Utilizator existent! Mergeti catre login pentru autentificare.");
      }
    } else if (!emailRegexTest(email)) {
      alert("Introduceti o adresa de mail valida!");
    } else if (!email || !plainPassword || !firstName) {
      alert("Introduceti valori pentru toate campurile obligatorii!");
    }
  };

  if (singupState === "SignUp")
    return (
      <main className={classes.content}>
        <div style={{ height: navbarHeight }}></div>
        <div className={classes.contentBackgroundHolder}>
          <div className={classes.innerContentBackground}>
            <h1>Creare cont</h1>

            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Prenume
            </div>
            <Input
              style={{
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                marginBottom: "10px",
                color: PSGold,
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "13rem",
                height: "2.5rem",
              }}
              disableUnderline
              type="text"
              id="firstName"
            />
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Nume</div>
            <Input
              style={{
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                marginBottom: "10px",
                color: PSGold,
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "13rem",
                height: "2.5rem",
              }}
              disableUnderline
              type="text"
              id="lastName"
            />
            <br />
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Adresă de mail
            </div>
            <Input
              style={{
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                marginBottom: "10px",
                color: PSGold,
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "13rem",
                height: "2.5rem",
              }}
              disableUnderline
              type="mail"
              id="mail"
            />
            <br />
            <div>Parola</div>
            <OutlinedInput
              style={{
                border: `1px solid ${PSGold}`,
                borderRadius: "10px",
                marginBottom: "10px",
                color: PSGold,
                paddingLeft: "5px",
                paddingRight: "5px",
                width: "13rem",
                height: "2.5rem",
              }}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment style={{ height: "2.5rem" }} position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ marginRight: "1%" }}
                  >
                    {showPassword ? (
                      <VisibilityOff style={{ color: PSGold }} />
                    ) : (
                      <Visibility style={{ color: PSGold }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              id="password"
            />
            <br />
            <br />
            <div>
              <div style={{ marginBottom: "0.5rem" }}>
                Prin crearea unui cont, sunteți de acord cu
              </div>
              <a
                style={{ color: PSGold }}
                href={"/TERMENI SI CONDITII.pdf"}
                target="new"
              >
                Termenii și Condițiile
              </a>
            </div>
            <br />
            <Button
              style={{ color: PSDark, backgroundColor: PSGold }}
              onClick={handleSignUp}
            >
              Creare cont
            </Button>
            <br />
            <br />
          </div>
        </div>
        <Grid container justifyContent={"center"} spacing={4} />
      </main>
    );

  if (singupState === "MailVerification") return <MailVerification />;

  return <div>Ceva nu a funcționat</div>;
};

const MailVerification = (props) => {
  const { navbarHeight } = props;
  const classes = useStyles();

  return (
    <main className={classes.centeredContent}>
      <div className={classes.navHolder}></div>
      <div className={classes.pageTextColor}>
        <div>
          <div style={{ height: navbarHeight }}></div>
          <h1>Contul tău a fost creat</h1>
          <br />
          <br />
          <h2>
            Felicitări pentru crearea contului tău! Pentru a finaliza procesul
            de înregistrare, un mail de verificare a fost trimis la adresa de
            email introdusă. Valabilitatea link-ului de verificare este de 24 de
            ore.
          </h2>
          <br />
          <br />
          <div className={classes.iconHolder}>
            <Expiration />
          </div>
          <br />
          <br />
        </div>
      </div>
    </main>
  );
};

export default SignUp;
