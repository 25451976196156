import React, { useEffect, useState } from "react";
import useStyles from "./stylesAdd";
import { serverUrl } from "../../../consts/serverUrl";
import { ArrowBack } from "@mui/icons-material";
import { DatePicker } from "react-datepicker-configurable";

export const AddPromotion = (props) => {
  const { setPage, navbarHeight, setPreviousPage } = props;
  const classes = useStyles();
  const [expirationDate, setExpirationDate] = useState(0);

  const handleBackToManage = () => {
    setPage("ManagePromos");
  };

  const handleClickBack = () => {
    setPage("ManagePromos");
  };

  const handleAddProduct = async () => {
    const adminJwt = localStorage.getItem("proseccoshop_admin_token") || "";
    if (!adminJwt) {
      alert("Token de admin inexistent!");
      return;
    }

    const promotionName = document.getElementById("promotionName").value;
    const promotionCode = document.getElementById("promotionCode").value;
    const discountPercentage = parseFloat(
      document.getElementById("discountPercentage").value
    );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminJwt}`,
      },
      body: JSON.stringify({
        promotionName,
        promotionCode: promotionCode.toLowerCase(),
        discountPercentage,
        expirationDate: expirationDate ? expirationDate : 0,
      }),
    };

    try {
      const response = await fetch(
        `${serverUrl}/add-promotion`,
        requestOptions
      );
      if (response.ok) {
        alert("Promotie adaugata cu succes!");
      } else {
        alert("Adaugarea a esuat");
      }

      handleClickBack();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setPreviousPage("");
  }, []);

  return (
    <div className={classes.cartBackground}>
      <div style={{ height: navbarHeight }}></div>
      <button onClick={handleBackToManage}>
        <ArrowBack></ArrowBack>
        <br></br>Inapoi la Product Manager Page
      </button>
      <div className={classes.content}>
        <h2>Adaugare promotie</h2>
        <br />
        <div>
          <div>Numele Promotiei</div>
          <input id="promotionName" type="text" /> <br />
          <br />
          <div>Codul Promotiei</div>
          <input id="promotionCode" type="text" /> <br />
          <br />
          <div>Discount (procente)</div>
          <input id="discountPercentage" type="text" /> <br />
          <br></br>
          <div>Data de expirare</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DatePicker
              includeTime
              onChange={(newDate) => {
                setExpirationDate(new Date(newDate.$d).getTime());
              }}
            ></DatePicker>
          </div>
          <br></br>
          <br />
          <button onClick={handleAddProduct}>Adauga promotia</button>
          <br />
          <br />
          <button onClick={handleClickBack}>Inapoi</button>
        </div>
      </div>
      <br />
    </div>
  );
};
